var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "translate",
      style: _vm.backCss,
      on: { mouseup: _vm.allMouseup, mousemove: _vm.allMousemove },
    },
    [
      _c("content-list", {
        ref: "contentList",
        attrs: {
          contentList: _vm.contentList,
          diffsTotal: _vm.diffsTotal,
          isAutoSave: _vm.isAutoSave,
          contentEdit: _vm.contentEdit,
          toStagesAnnotation: _vm.toStagesAnnotation,
          bottomHeight: _vm.bottomHeight,
          allStages: _vm.allStages,
          isLocalZhongShen: _vm.isLocalZhongShen,
          isAdmin: _vm.isAdmin,
          rightAnnotationList: _vm.rightAnnotationList,
          leftAnnotationList: _vm.leftAnnotationList,
          showAnnotationList: _vm.showAnnotationList,
          userInfo: _vm.userInfo,
          departName: _vm.departName,
          contentToStages: _vm.contentToStages,
          taskDetail: _vm.taskDetail,
          textList: _vm.textList,
          toStages: _vm.toStages,
          allOption: _vm.allOption,
          diffs: _vm.diffs,
          changeAll: _vm.changeAll,
          showTextArea: _vm.showTextArea,
          showDiffs: _vm.showDiffs,
          authList: _vm.authList,
          diffTitle: _vm.diffTitle,
          selectLocalId: _vm.selectLocalId,
          isWindows: _vm.isWindows,
          selectedDiffIdx: _vm.selectedDiffIdx,
          errorDictList: _vm.errorDictList,
          color: _vm.color,
          showOptions: _vm.showOptions,
        },
        on: {
          closeContentDarg: _vm.closeContentDarg,
          pageOptionChange: _vm.pageOptionChange,
          closeLocal: _vm.closeLocal,
          changeOption: _vm.changeOption,
          changeContentDarg: _vm.changeContentDarg,
          saveContentLoacalTranslate: _vm.saveContentLoacalTranslate,
          getShowImgListPostions: _vm.getShowImgListPostions,
          getPageListPostions: _vm.getPageListPostions,
          changeSelectList: _vm.changeSelectList,
          saveOldLocal: _vm.saveOldLocal,
          getLocalList: _vm.getLocalList,
          changeContentEdit: _vm.changeContentEdit,
          showDiff: _vm.showDiff,
          searchTextList: _vm.searchTextList,
          saveLoacal: _vm.saveLoacal,
          showImgHandleImgClick: _vm.showImgHandleImgClick,
          clearSelectedDiffIdxTwo: _vm.clearSelectedDiffIdxTwo,
          changeCheckedStages: _vm.changeCheckedStages,
          changeHasLocal: _vm.changeHasLocal,
          changeRadioValue: _vm.changeRadioValue,
          changeCheckedToStages: _vm.changeCheckedToStages,
          changeSelectDiff: _vm.changeSelectDiff,
          toLocal: _vm.toLocal,
          changeUpdateStatus: _vm.changeUpdateStatus,
          changeMachineStatus: _vm.changeMachineStatus,
          changeError: _vm.changeError,
          deleError: _vm.deleError,
          saveContentLoacal: _vm.saveContentLoacal,
          deleContentLoacal: _vm.deleContentLoacal,
          toLocalOld: _vm.toLocalOld,
          showTextAreaChange: _vm.showTextAreaChange,
          showDiffChange: _vm.showDiffChange,
          cancelErrorEdit: _vm.cancelErrorEdit,
          anTranslate: _vm.anTranslate,
          translateAll: _vm.translateAll,
        },
      }),
      _c("div", { staticClass: "translate-top" }, [
        _c("div", { staticClass: "translate-top-center" }, [
          _c(
            "div",
            { staticClass: "translate-top-center-content" },
            [
              _c("center-button-list", {
                ref: "centerButtonList",
                attrs: {
                  selectedPlatte: _vm.selectedPlatte,
                  taskConfirmStatus: _vm.taskConfirmStatus,
                  showOptions: _vm.showOptions,
                  changeAll: _vm.changeAll,
                  authList: _vm.authList,
                  isAddAnnotation: _vm.isAddAnnotation,
                  taskDetail: _vm.taskDetail,
                  selectTextAreaData: _vm.selectTextAreaData,
                  selectedDiff: _vm.selectedDiff,
                  isAutoSave: _vm.isAutoSave,
                  allOption: _vm.allOption,
                  showTextArea: _vm.showTextArea,
                  scrollSync: _vm.scrollSync,
                  color: _vm.color,
                },
                on: {
                  changeTaskConfirmStatus: _vm.changeTaskConfirmStatus,
                  changeSyncWidth: _vm.changeSyncWidth,
                  changeSyncPer: _vm.changeSyncPer,
                  showTextAreaChange: _vm.showTextAreaChange,
                  textBatchChange: _vm.textBatchChange,
                  downLoadImg: _vm.downLoadImg,
                  nextData: _vm.nextData,
                  changeSelectedDiff: _vm.changeSelectedDiff,
                  changeAutoSave: _vm.changeAutoSave,
                  backData: _vm.backData,
                  changeShowImgListWidth: _vm.changeShowImgListWidth,
                  changeScrollSync: _vm.changeScrollSync,
                  changeIsAddAnnotation: _vm.changeIsAddAnnotation,
                  openPlatteModal: _vm.openPlatteModal,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "translate-bottom" },
        [
          _c("div", { ref: "pageList", staticClass: "translate-bottom-left" }, [
            _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass: "translate-bottom-left-show-icon",
                    on: { click: _vm.changePageListWidth },
                  },
                  [
                    _vm.pageListShow
                      ? _c("a-icon", { attrs: { type: "left" } })
                      : _c("a-icon", { attrs: { type: "right" } }),
                  ],
                  1
                ),
                _c("page-list", {
                  ref: "pageListContent",
                  attrs: {
                    allOption: _vm.allOption,
                    selectIdx: _vm.selectIdx,
                    showOptions: _vm.showOptions,
                    pageListPostions: _vm.pageListPostions,
                    authList: _vm.authList,
                    pageListShow: _vm.pageListShow,
                    taskDetail: _vm.taskDetail,
                    chapterList: _vm.chapterList,
                  },
                  on: {
                    changeShowScroll: _vm.changeShowScroll,
                    getPageListPostions: _vm.getPageListPostions,
                    pageOptionChange: _vm.pageOptionChange,
                    changeSelectIdx: _vm.changeSelectIdx,
                    changePage: _vm.changePage,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              ref: "showImg",
              staticClass: "translate-bottom-center",
              style: {
                width: _vm.contentList.some((item) => item.isFixed)
                  ? "calc(85% - 400px)"
                  : "85%",
              },
              on: {
                mouseover: _vm.imgContentMouseover,
                mouseleave: _vm.imgContentMouseout,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "background-color": "#f0f2f5" } },
                [
                  _c("show-img", {
                    ref: "showImgContent",
                    attrs: {
                      changeAll: _vm.changeAll,
                      showTextArea: _vm.showTextArea,
                      hasLocal: _vm.hasLocal,
                      showDiffs: _vm.showDiffs,
                      radioValue: _vm.radioValue,
                      checkedToStages: _vm.checkedToStages,
                      toStagesAnnotation: _vm.toStagesAnnotation,
                      toStagesLeaveMessage: _vm.toStagesLeaveMessage,
                      selectedPlatte: _vm.selectedPlatte,
                      isDownLoad: _vm.isDownLoad,
                      isChangePageList: _vm.isChangePageList,
                      isLocalZhongShen: _vm.isLocalZhongShen,
                      diffFilterString: _vm.diffFilterString,
                      contentEdit: _vm.contentEdit,
                      isAutoSave: _vm.isAutoSave,
                      selectTextAreaData: _vm.selectTextAreaData,
                      checkedStages: _vm.checkedStages,
                      isOpenVideo: _vm.isOpenVideo,
                      selectedDiff: _vm.selectedDiff,
                      selectedDiffIdx: _vm.selectedDiffIdx,
                      authList: _vm.authList,
                      rightAnnotationList: _vm.rightAnnotationList,
                      leftAnnotationList: _vm.leftAnnotationList,
                      selectLocalId: _vm.selectLocalId,
                      showImgListWidth: _vm.showImgListWidth,
                      diffTitle: _vm.diffTitle,
                      isAddAnnotation: _vm.isAddAnnotation,
                      departName: _vm.departName,
                      isWindows: _vm.isWindows,
                      stageSize: _vm.stageSize,
                      diffs: _vm.diffs,
                      isToDiff: _vm.isToDiff,
                      errorDictList: _vm.errorDictList,
                      scrollSync: _vm.scrollSync,
                      changeScrollHeihgt: _vm.changeScrollHeihgt,
                      showImgLeftListPostions: _vm.showImgLeftListPostions,
                      showOptions: _vm.showOptions,
                      color: _vm.color,
                      userInfo: _vm.userInfo,
                      selectIdx: _vm.selectIdx,
                      allOption: _vm.allOption,
                      taskDetail: _vm.taskDetail,
                    },
                    on: {
                      closeLocal: _vm.closeLocal,
                      syncChangeScroll: _vm.syncChangeScroll,
                      selectTextArea: _vm.selectTextArea,
                      clearSelectTextAreaData: _vm.clearSelectTextAreaData,
                      pushSelectTextAreaData: _vm.pushSelectTextAreaData,
                      showImgHandleImgClick: _vm.showImgHandleImgClick,
                      saveDiffError: _vm.saveDiffError,
                      saveError: _vm.saveError,
                      clearSelectedDiffIdxTwo: _vm.clearSelectedDiffIdxTwo,
                      deleError: _vm.deleError,
                      selectDiff: _vm.selectDiff,
                      clearSelectedDiffIdx: _vm.clearSelectedDiffIdx,
                      changeSelectLocalId: _vm.changeSelectLocalId,
                      changeShowOptions: _vm.changeShowOptions,
                      changeAnnotationList: _vm.changeAnnotationList,
                      closeDiffModal: _vm.closeDiffModal,
                      saveLoacal: _vm.saveLoacal,
                      saveDiffLoacal: _vm.saveDiffLoacal,
                      diff: _vm.diff,
                      changeSelectIdx: _vm.changeSelectIdx,
                      savePlatte: _vm.savePlatte,
                      saveScrollTop: _vm.saveScrollTop,
                      moveOption: _vm.moveOption,
                      createdCanvas: _vm.createdCanvas,
                      getShowImgListPostions: _vm.getShowImgListPostions,
                      closePlatte: _vm.closePlatte,
                      pushOption: _vm.pushOption,
                      changeOption: _vm.changeOption,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.contentList.some((item) => item.isFixed && item.visible)
            ? _c("div", {
                staticClass: "fixed-sidebar",
                style: {
                  height: _vm.contentList.some((item) => item.contentDrag)
                    ? "100% !important"
                    : "",
                  boxShadow: _vm.dragContent.isOut
                    ? " 2px 1px 6px 2px " + _vm.color
                    : "",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { ref: "optionList", staticClass: "translate-bottom-right" },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "option-list" },
                  [
                    _vm.optionListWidth > 60
                      ? _vm._l(_vm.contentList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              class: item.visible
                                ? "select-option option"
                                : "option",
                              style: `font-size:${
                                (_vm.optionListWidth / 8).toFixed(0) - 0 > 14
                                  ? 14
                                  : (_vm.optionListWidth / 8).toFixed(0)
                              }px;padding:0 ${
                                (_vm.optionListWidth / 12).toFixed(0) - 0 > 5
                                  ? 5
                                  : (_vm.optionListWidth / 12).toFixed(0)
                              }px 0 ${
                                (_vm.optionListWidth / 12).toFixed(0) - 0 > 5
                                  ? 5
                                  : (_vm.optionListWidth / 12).toFixed(0)
                              }px`,
                              attrs: { id: "option-" + item.id },
                              on: {
                                click: (e) => {
                                  _vm.optionClick(e, item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.optionTitle) +
                                  "\n            "
                              ),
                            ]
                          )
                        })
                      : _vm._l(_vm.contentList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              class: item.visible
                                ? "select-option option"
                                : "option",
                              staticStyle: { padding: "0 5px 0 5px" },
                              attrs: { id: "option" + item.id },
                              on: {
                                click: (e) => {
                                  _vm.optionClick(e, item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.optionTitleMini) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _c("playvideo-modal", {
            ref: "videoModal",
            attrs: { innerWidth: _vm.innerWidth, isSys: true },
            on: {
              changeVideoWidth: _vm.changeVideoWidth,
              cancel: _vm.videCancel,
            },
          }),
        ],
        1
      ),
      _vm.selectedPlatte
        ? _c("div", { staticClass: "platte-shade" })
        : _vm._e(),
      _vm.allLoadding
        ? _c(
            "div",
            { staticClass: "all-loading" },
            [
              _c(
                "a-spin",
                {
                  attrs: {
                    spinning: _vm.allLoadding,
                    size: "large",
                    tip: _vm.loaddingText,
                  },
                },
                [
                  _c("a-icon", {
                    staticStyle: { "font-size": "40px" },
                    attrs: { slot: "indicator", type: "loading", spin: "" },
                    slot: "indicator",
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }