<template>
  <div class="translate" :style="backCss" @mouseup="allMouseup" @mousemove="allMousemove">
    <content-list
      ref="contentList"
      @closeContentDarg="closeContentDarg"
      @pageOptionChange="pageOptionChange"
      @closeLocal="closeLocal"
      @changeOption="changeOption"
      @changeContentDarg="changeContentDarg"
      @saveContentLoacalTranslate="saveContentLoacalTranslate"
      @getShowImgListPostions="getShowImgListPostions"
      @getPageListPostions="getPageListPostions"
      @changeSelectList="changeSelectList"
      @saveOldLocal="saveOldLocal"
      @getLocalList="getLocalList"
      @changeContentEdit="changeContentEdit"
      @showDiff="showDiff"
      @searchTextList="searchTextList"
      @saveLoacal="saveLoacal"
      @showImgHandleImgClick="showImgHandleImgClick"
      @clearSelectedDiffIdxTwo="clearSelectedDiffIdxTwo"
      @changeCheckedStages="changeCheckedStages"
      @changeHasLocal="changeHasLocal"
      @changeRadioValue="changeRadioValue"
      @changeCheckedToStages="changeCheckedToStages"
      :contentList="contentList"
      :diffsTotal="diffsTotal"
      :isAutoSave="isAutoSave"
      :contentEdit="contentEdit"
      :toStagesAnnotation="toStagesAnnotation"
      :bottomHeight="bottomHeight"
      :allStages="allStages"
      :isLocalZhongShen="isLocalZhongShen"
      :isAdmin="isAdmin"
      :rightAnnotationList="rightAnnotationList"
      :leftAnnotationList="leftAnnotationList"
      :showAnnotationList="showAnnotationList"
      :userInfo="userInfo"
      :departName="departName"
      :contentToStages="contentToStages"
      :taskDetail="taskDetail"
      :textList="textList"
      :toStages="toStages"
      :allOption="allOption"
      :diffs="diffs"
      :changeAll="changeAll"
      @changeSelectDiff="changeSelectDiff"
      @toLocal="toLocal"
      @changeUpdateStatus="changeUpdateStatus"
      @changeMachineStatus="changeMachineStatus"
      @changeError="changeError"
      @deleError="deleError"
      @saveContentLoacal="saveContentLoacal"
      @deleContentLoacal="deleContentLoacal"
      @toLocalOld="toLocalOld"
      :showTextArea="showTextArea"
      @showTextAreaChange="showTextAreaChange"
      :showDiffs="showDiffs"
      @showDiffChange="showDiffChange"
      :authList="authList"
      :diffTitle="diffTitle"
      :selectLocalId="selectLocalId"
      :isWindows="isWindows"
      @cancelErrorEdit="cancelErrorEdit"
      @anTranslate="anTranslate"
      @translateAll="translateAll"
      :selectedDiffIdx="selectedDiffIdx"
      :errorDictList="errorDictList"
      :color="color"
      :showOptions="showOptions"
    />
    <div class="translate-top">
      <div class="translate-top-center">
        <div class="translate-top-center-content">
          <center-button-list
            :selectedPlatte="selectedPlatte"
            :taskConfirmStatus="taskConfirmStatus"
            @changeTaskConfirmStatus="changeTaskConfirmStatus"
            :showOptions="showOptions"
            :changeAll="changeAll"
            :authList="authList"
            :isAddAnnotation="isAddAnnotation"
            :taskDetail="taskDetail"
            :selectTextAreaData="selectTextAreaData"
            :selectedDiff="selectedDiff"
            :isAutoSave="isAutoSave"
            :allOption="allOption"
            @changeSyncWidth="changeSyncWidth"
            @changeSyncPer="changeSyncPer"
            :showTextArea="showTextArea"
            @showTextAreaChange="showTextAreaChange"
            @textBatchChange="textBatchChange"
            @downLoadImg="downLoadImg"
            @nextData="nextData"
            @changeSelectedDiff="changeSelectedDiff"
            @changeAutoSave="changeAutoSave"
            @backData="backData"
            @changeShowImgListWidth="changeShowImgListWidth"
            @changeScrollSync="changeScrollSync"
            @changeIsAddAnnotation="changeIsAddAnnotation"
            @openPlatteModal="openPlatteModal"
            :scrollSync="scrollSync"
            :color="color"
            ref="centerButtonList"
          />
        </div>
      </div>
    </div>
    <div class="translate-bottom">
      <div class="translate-bottom-left" ref="pageList">
        <div>
          <div class="translate-bottom-left-show-icon" @click="changePageListWidth">
            <a-icon v-if="pageListShow" type="left" />
            <a-icon v-else type="right" />
          </div>
          <page-list
            ref="pageListContent"
            @changeShowScroll="changeShowScroll"
            @getPageListPostions="getPageListPostions"
            @pageOptionChange="pageOptionChange"
            @changeSelectIdx="changeSelectIdx"
            :allOption="allOption"
            :selectIdx="selectIdx"
            :showOptions="showOptions"
            :pageListPostions="pageListPostions"
            :authList="authList"
            :pageListShow="pageListShow"
            @changePage="changePage"
            :taskDetail="taskDetail"
            :chapterList="chapterList"
          />
        </div>
      </div>
      <div
        class="translate-bottom-center"
        :style="{ width: contentList.some(item => item.isFixed) ? 'calc(85% - 400px)' : '85%' }"
        ref="showImg"
        @mouseover="imgContentMouseover"
        @mouseleave="imgContentMouseout"
      >
        <div style="background-color: #f0f2f5;">
          <show-img
            ref="showImgContent"
            :changeAll="changeAll"
            :showTextArea="showTextArea"
            :hasLocal="hasLocal"
            :showDiffs="showDiffs"
            :radioValue="radioValue"
            :checkedToStages="checkedToStages"
            :toStagesAnnotation="toStagesAnnotation"
            :toStagesLeaveMessage="toStagesLeaveMessage"
            :selectedPlatte="selectedPlatte"
            :isDownLoad="isDownLoad"
            :isChangePageList="isChangePageList"
            :isLocalZhongShen="isLocalZhongShen"
            :diffFilterString="diffFilterString"
            :contentEdit="contentEdit"
            :isAutoSave="isAutoSave"
            :selectTextAreaData="selectTextAreaData"
            :checkedStages="checkedStages"
            @closeLocal="closeLocal"
            @syncChangeScroll="syncChangeScroll"
            @selectTextArea="selectTextArea"
            @clearSelectTextAreaData="clearSelectTextAreaData"
            @pushSelectTextAreaData="pushSelectTextAreaData"
            @showImgHandleImgClick="showImgHandleImgClick"
            :isOpenVideo="isOpenVideo"
            :selectedDiff="selectedDiff"
            :selectedDiffIdx="selectedDiffIdx"
            @saveDiffError="saveDiffError"
            @saveError="saveError"
            @clearSelectedDiffIdxTwo="clearSelectedDiffIdxTwo"
            :authList="authList"
            @deleError="deleError"
            @selectDiff="selectDiff"
            @clearSelectedDiffIdx="clearSelectedDiffIdx"
            @changeSelectLocalId="changeSelectLocalId"
            :rightAnnotationList="rightAnnotationList"
            :leftAnnotationList="leftAnnotationList"
            :selectLocalId="selectLocalId"
            :showImgListWidth="showImgListWidth"
            :diffTitle="diffTitle"
            :isAddAnnotation="isAddAnnotation"
            :departName="departName"
            :isWindows="isWindows"
            :stageSize="stageSize"
            :diffs="diffs"
            :isToDiff="isToDiff"
            :errorDictList="errorDictList"
            @changeShowOptions="changeShowOptions"
            :scrollSync="scrollSync"
            :changeScrollHeihgt="changeScrollHeihgt"
            :showImgLeftListPostions="showImgLeftListPostions"
            :showOptions="showOptions"
            :color="color"
            :userInfo="userInfo"
            :selectIdx="selectIdx"
            :allOption="allOption"
            @changeAnnotationList="changeAnnotationList"
            @closeDiffModal="closeDiffModal"
            @saveLoacal="saveLoacal"
            @saveDiffLoacal="saveDiffLoacal"
            @diff="diff"
            @changeSelectIdx="changeSelectIdx"
            @savePlatte="savePlatte"
            @saveScrollTop="saveScrollTop"
            @moveOption="moveOption"
            @createdCanvas="createdCanvas"
            @getShowImgListPostions="getShowImgListPostions"
            @closePlatte="closePlatte"
            @pushOption="pushOption"
            @changeOption="changeOption"
            :taskDetail="taskDetail"
          />
        </div>
      </div>
      <div
        class="fixed-sidebar"
        :style="{
          height: contentList.some(item => item.contentDrag) ? '100% !important' : '',
          boxShadow: dragContent.isOut ? ' 2px 1px 6px 2px ' + color : ''
        }"
        v-if="contentList.some(item => item.isFixed && item.visible)"
      ></div>
      <div class="translate-bottom-right" ref="optionList">
        <div>
          <div class="option-list">
            <!-- <div class="translate-bottom-right-show-icon" @click="changeOptionListWidth">
              <a-icon v-if="optionListWidth <= 60" type="left" />
              <a-icon v-else type="right" />
            </div> -->
            <template v-if="optionListWidth > 60">
              <div
                v-for="item in contentList"
                @click="
                  e => {
                    optionClick(e, item)
                  }
                "
                :class="item.visible ? 'select-option option' : 'option'"
                :key="item.id"
                :id="'option-' + item.id"
                :style="
                  `font-size:${
                    (optionListWidth / 8).toFixed(0) - 0 > 14 ? 14 : (optionListWidth / 8).toFixed(0)
                  }px;padding:0 ${
                    (optionListWidth / 12).toFixed(0) - 0 > 5 ? 5 : (optionListWidth / 12).toFixed(0)
                  }px 0 ${(optionListWidth / 12).toFixed(0) - 0 > 5 ? 5 : (optionListWidth / 12).toFixed(0)}px`
                "
              >
                {{ item.optionTitle }}
              </div>
            </template>
            <template v-else>
              <div
                v-for="item in contentList"
                @click="
                  e => {
                    optionClick(e, item)
                  }
                "
                style="padding:0 5px 0 5px"
                :id="'option' + item.id"
                :class="item.visible ? 'select-option option' : 'option'"
                :key="item.id"
              >
                {{ item.optionTitleMini }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <playvideo-modal
        @changeVideoWidth="changeVideoWidth"
        :innerWidth="innerWidth"
        :isSys="true"
        ref="videoModal"
        @cancel="videCancel"
      />
    </div>
    <div class="platte-shade" v-if="selectedPlatte"></div>
    <div v-if="allLoadding" class="all-loading">
      <a-spin :spinning="allLoadding" size="large" :tip="loaddingText">
        <a-icon slot="indicator" type="loading" style="font-size: 40px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { getAction, postAction, v1postAction, v1DeleteAction, deleteAction } from '@/api/manage'
import { USER_INFO } from '@/store/mutation-types'
import centerButtonList from './components/centerButtonList'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { simpleDebounce } from '@/utils/util'
import showImg from './components/showImg'
import { checkPermission } from './utils/hasPermission'
import { batchStyleInsertHeader } from '@/utils/util'
import pageList from './components/pageList'
import contentList from './components/contentList'
import Vue from 'vue'
import { Trumpet } from '@icon-park/vue'
import domtoimage from '@/views/translate/dom-to-image'
import JsPDF from 'jspdf'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import axios from 'axios'
import playvideoModal from '@/components/playvideo-move-modal'
export default {
  components: {
    contentList,
    showImg,
    centerButtonList,
    playvideoModal,
    pageList
  },
  mixins: [UploadFileToOssMixins],
  data() {
    return {
      textList: [],
      imagePlayWidth: '1000px',
      taskConfirmStatus: true,
      color: '',
      allOption: [],
      toStages: [],
      loaddingText: '加载中',
      taskDetail: {
        departName: ''
      },
      pageListShow: true,
      isToDiff: false,
      skipImgId: null,
      skipItem: null,
      isSkip: false,
      leftAnnotationList: [],
      toStagesLeaveMessage: [],
      rightAnnotationList: [],
      showAnnotationList: [],
      taskStatus: '等待',
      showImgListWidth: '100%',
      stageSize: { width: 0, height: 0 },
      checkedToStages: ['制作环节'],
      showTextArea: 'ON',
      showDiffs: 'ON',
      contentList: [
        {
          zIndex: 999,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 1,
          title: '对比结果',
          optionTitle: '对比结果',
          optionTitleMini: '对',
          pushpinColor: '#4a4a4a',
          visible: false,
          isPushpin: false,
          allHeight: false,
          showContent: true,
          isOpacity: false,
          isFixed: false,
          isSort: false,
          order: 0,
          isOut: false
        },
        {
          zIndex: 999,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 2,
          title: '批注列表',
          optionTitle: '批注列表',
          optionTitleMini: '批',
          pushpinColor: '#4a4a4a',
          visible: false,
          isPushpin: false,
          allHeight: false,
          showContent: true,
          isOpacity: false,
          isFixed: false,
          isSort: false,
          order: 1,
          isOut: false
        },
        {
          zIndex: 999,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 3,
          title: '译文列表',
          optionTitle: '译文列表',
          optionTitleMini: '译',
          pushpinColor: '#4a4a4a',
          visible: false,
          isPushpin: false,
          allHeight: false,
          showContent: true,
          isOpacity: false,
          isFixed: false,
          isSort: false,
          order: 1,
          isOut: false
        }
      ],
      showImgWidth: 0,
      changeNum: 12,
      selectIdx: 0,
      radioValue: 0,
      optionListWidth: 0,
      pageListShow: true,
      showOptions: [
        {
          list: [
            {
              title: '实时',
              value: '0-0',
              checked: false,
              localChecked: false,
              scrollTop: 0,
              allImgHeight: 0,
              changeWidth: 0,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: []
            }
            /*             {
              title: '原稿',
              value: '0-1',
              checked: false,
              scrollTop: 0,
              allImgHeight: 0,
              localChecked: false,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: []
            },
            {
              title: '原稿JPG',
              value: '0-2',
              checked: true,
              scrollTop: 0,
              allImgHeight: 0,
              localChecked: false,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: []
            } */
          ]
        },
        {
          list: [
            {
              title: '初稿',
              value: '1-0',
              checked: true,
              scrollTop: 0,
              allImgHeight: 0,
              changeWidth: 0,
              localChecked: false,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: []
            }
          ]
        }
      ],
      selectedPlatte: false,
      selectedDiff: false,
      allLoadding: false,
      contentEdit: false,
      isChangeContentList: true,
      isAddAnnotation: false,
      pageListShow: true,
      showImgLeftListPostions: [],
      selectTextAreaData: [],
      authList: [],
      pageListPostions: [],
      contentToStages: [],
      chapterList: [],
      checkedStages: [],
      changeScrollHeihgt: 0,
      diffsTotal: 0,
      changeAll: true,
      scrollSync: true,
      saveScrollId: null,
      rollPositionArray: [],
      changeProcess: [],
      saveIndex: 0,
      isAutoSave: false,
      isDownLoad: false,
      isOpenVideo: false,
      loadLock: false,
      isAdmin: false,
      hasLocal: true,
      departName: '',
      errorDictList: [],
      toStagesAnnotation: [],
      emjDictList: [],
      dragContent: {
        zIndex: 800,
        mouseX: 0,
        mouseY: 100,
        contentDrag: false,
        isOpacity: false,
        isFixed: false,
        isSort: false,
        order: 0,
        isOut: false
      },
      offsetX: -1,
      isWindows: true,
      isLocalZhongShen: false,
      topHeight: 52,
      bottomHeight: 0,
      diffs: [],
      allStages: [],
      diffTitle: '',
      selectLocalId: -1,
      isChangePageList: false,
      selectOldLocalId: -1,
      innerWidth: 0,
      selectedDiffIdx: '',
      diffLeftTtile: '',
      diffFilterString: '',
      imgCenterMove: false,
      oldShowOptions: [
        {
          list: [
            {
              title: '实时',
              value: '0-0',
              checked: false,
              localChecked: false,
              scrollTop: 0,
              allImgHeight: 0,
              changeWidth: 0,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: []
            }
            /*             {
              title: '原稿',
              value: '0-1',
              checked: false,
              scrollTop: 0,
              allImgHeight: 0,
              localChecked: false,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: []
            },
            {
              title: '原稿JPG',
              value: '0-2',
              checked: true,
              scrollTop: 0,
              allImgHeight: 0,
              localChecked: false,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: []
            } */
          ]
        },
        {
          list: [
            {
              title: '初稿',
              value: '1-0',
              checked: true,
              scrollTop: 0,
              allImgHeight: 0,
              changeWidth: 0,
              localChecked: false,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: []
            }
          ]
        }
      ]
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color
      }
    }
  },
  created() {
    document.title = '加载中'
    this.loadLock = this.$route.query.loadLock ? true : false
    this.getAllData()
    this.isWindows = navigator.userAgent.toLowerCase().indexOf('windows') > -1
  },
  beforeDestroy() {
    window.removeEventListener('mousewheel', this.handleScroll)
  },
  mounted() {
    this.keyDown()
    this.userInfo = Vue.ls.get(USER_INFO)
    this.showImgWidth = this.$refs.showImg.clientWidth
    if (this.$refs.optionList) {
      this.optionListWidth = this.$refs.optionList.clientWidth
    }
    this.innerWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.innerWidth = window.innerWidth
      if (this.$refs.optionList) {
        this.optionListWidth = this.$refs.optionList.clientWidth
      }
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.isChangePage = true
      }
      this.getShowImgListPostions()
      this.getPageListPostions()
      if (!this.dragContent.isFixed) this.dragContent.mouseX = 0
    })
    this.showTextArea = localStorage.getItem('show-text-area') || 'ON'
    this.showDiffs = localStorage.getItem('show-diff') || 'ON'
  },
  watch: {
    selectLocalId() {
      this.showImgHandleImgClick()
    },
    selectedDiffIdx() {
      this.showImgHandleImgClick()
    },
    changeProcess() {
      if (this.changeProcess.length > 5) {
        let arr = this.changeProcess.filter((c, cIdx) => this.changeProcess.length - cIdx <= 5)
        this.saveIndex = this.saveIndex - (this.changeProcess.length - arr.length)
        this.changeProcess = arr
      }
    },
    contentList: {
      handler(newVal, oldVal) {
        if (!this.isChangeContentList) {
          this.saveContentList()
        }
      },
      deep: true
    }
  },
  methods: {
    changeSyncWidth(data) {
      let changeWidth = 0
      let changeWidthShowOptionIndex = -1
      this.showOptions.forEach((showOption, showOptionIndex) => {
        if (showOption.list.find(option => option.title == data.label)) {
          changeWidth =
            showOption.list.find(option => option.title == data.label).manuscriptList[0].imgWidth - data.value
          changeWidthShowOptionIndex = showOptionIndex
        }
      })
      if (changeWidthShowOptionIndex == -1) return
      this.$refs.showImgContent.isChangePage = true
      this.showOptions.forEach((s, idx) => {
        if (idx == changeWidthShowOptionIndex) {
          if (!s.changeWidth) s.changeWidth = 0
          s.changeWidth = s.changeWidth + changeWidth
        } else if (idx == changeWidthShowOptionIndex + 1) {
          if (!s.changeWidth) s.changeWidth = 0
          s.changeWidth = s.changeWidth - changeWidth
        } else if (idx == changeWidthShowOptionIndex - 1 && changeWidthShowOptionIndex == this.showOptions.length - 1) {
          if (!s.changeWidth) s.changeWidth = 0
          s.changeWidth = s.changeWidth - changeWidth
        }
      })
      this.$refs.showImgContent.isShow = false
      let timeOut = setTimeout(() => {
        this.$refs.showImgContent.isShow = true
        clearTimeout(timeOut)
      }, 0)
      this.$nextTick(() => {
        this.getShowImgListPostions()
        this.$refs.centerButtonList.changeSyncWidth()
      })
    },
    changeSyncPer(data) {
      this.$refs.showImgContent.isChangePage = true
      this.showOptions.forEach((showOption, showOptionIndex) => {
        if (showOption.list.find(option => option.title == data.label)) {
          showOption.changePer = data.value - 0
        }
      })
      this.$refs.showImgContent.isShow = false
      let timeOut = setTimeout(() => {
        this.$refs.showImgContent.isShow = true
        clearTimeout(timeOut)
      }, 0)
      this.$nextTick(() => {
        this.getShowImgListPostions()
        /* this.$refs.centerButtonList.changeSyncWidth() */
      })
    },
    showTextAreaChange(type) {
      this.showTextArea = type
      localStorage.setItem('show-text-area', type)
      this.$nextTick(() => {
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.$forceUpdate()
        }
      })
    },
    showDiffChange(type) {
      this.showDiffs = type
      localStorage.setItem('show-diff', type)
      this.$nextTick(() => {
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.$forceUpdate()
        }
      })
    },
    saveContentList: simpleDebounce(async function() {
      this.saveContentListSim()
    }, 300),
    saveContentListSim() {
      postAction('user/annotation/style', { positionBar: JSON.stringify(this.contentList) })
    },
    async getContentList() {
      this.isChangeContentList = true
      const res = await getAction('user/annotation/style')
      if (res.code == 200) {
        if (res.data && res.data.positionBar) {
          this.contentList = JSON.parse(res.data.positionBar)
          this.$nextTick(() => {
            this.contentList.forEach(item => {
              if (document.getElementById('option-' + item.id) && item.visible) {
                let e = { target: document.getElementById('option-' + item.id) }
                item.mouseY = e.target.getClientRects()[0].y - e.target.getClientRects()[0].height

                if (this.$refs.contentList) {
                  this.$refs.contentList.noDrag = false
                }
                if (item.visible && item.isFixed) {
                  this.$nextTick(() => {
                    const dom = document.getElementById('content-list-' + item.id)
                    if (!dom) {
                      item.visible = false
                      return
                    }
                    dom.style.position = 'relative'
                    dom.style.left = '0'
                    dom.style.top = '0'
                    dom.style.zIndex = '1'
                    dom.style.boxShadow = 'none'
                    dom.style.borderRadius = '0'
                    document.getElementsByClassName('fixed-sidebar')[0].appendChild(dom)
                    if (this.$refs.optionList) {
                      this.optionListWidth = this.$refs.optionList.clientWidth
                    }
                    if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
                    /*                     this.getShowImgListPostions()
                    this.getPageListPostions() */
                  })
                }

                switch (item.id) {
                  case 2:
                    if (this.$refs.contentList) {
                      this.$refs.contentList.getLocalList()
                    }
                    break
                  case 3:
                    this.getTextList(2)
                    break
                }
              }
            })
            this.isChangeContentList = false
          })
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    changeContentEdit(type) {
      this.contentEdit = type
    },
    clearSelectTextAreaData() {
      this.selectTextAreaData = []
    },
    pushSelectTextAreaData(i) {
      this.selectTextAreaData.push(i)
    },
    selectTextArea(page, item, pageIdx) {
      this.selectTextAreaData = [item]
      if (this.$refs.contentList) {
        if (this.$refs.contentList.textDefaultKey.every(key => key != page.id)) {
          this.$refs.contentList.textDefaultKey.push(page.id + '')
        }
        this.$refs.contentList.toTextTwo(page, item, pageIdx)
      }
    },
    changeSelectList(page, item, pageIdx) {
      this.$refs.pageListContent.pageOptionChange('实时')
      this.getShowImgListPostions()
      let isChecked = false
      this.showOptions.forEach(showOption => {
        if (showOption.list.findIndex(option => option.title == '实时') > -1) {
          if (showOption.list.find(option => option.title == '实时').checked) {
            isChecked = true
          }
        }
      })
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.selectTextAreaId = item.id
      }
      this.selectIdx = pageIdx
      if (isChecked && this.$refs.pageListContent.optionTitle == '实时') {
        let num = 1
        let p = null
        let showOptionIndex = -1
        this.showOptions.forEach((showOption, index) => {
          if (showOption.list?.findIndex(option => option.title == '实时') > -1) {
            showOptionIndex = index
            if (showOption.list?.find(option => option.title == '实时').checked) {
              num = showOption.list.find(option => option.title == '实时').num
            }
            p = showOption.list
              ?.find(option => option.title == '实时')
              .manuscriptList[pageIdx].textAreas?.find(i => i.id == item.id)
          }
        })
        this.$nextTick(() => {
          if (p) {
            let y = (p.translateTextAreaRegion.y * num).toFixed(0) - 0
            document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop =
              this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 30
          }
          if (this.$refs.showImgContent) {
            this.$refs.showImgContent.isChangePage = false
            this.$refs.showImgContent.scrollIndex = showOptionIndex
          }
          /*           if (
            this.$refs.centerButtonList &&
            this.$refs.centerButtonList.checkSyncTabs.findIndex(item => item == '实时') == -1
          ) {
            this.$refs.centerButtonList.checkSyncTabs.push('实时')
          } */
        })
      } else {
        this.showOptions.forEach(showOption => {
          if (showOption.list?.find(option => option.title == '实时')) {
            this.$refs.pageListContent.optionTitle = '实时'
            showOption.list?.forEach(option => {
              if (option.title == '实时') {
                option.checked = true
              } else {
                option.checked = false
              }
            })
          }
        })
        this.$nextTick(() => {
          this.loadImg()
        })
        const timeOut = setTimeout(() => {
          let num = 1
          let p = null
          let showOptionIndex = -1
          this.showOptions.forEach((showOption, index) => {
            if (showOption.list?.findIndex(option => option.title == '实时') > -1) {
              showOptionIndex = index
              if (showOption.list?.find(option => option.title == '实时').checked) {
                num = showOption.list.find(option => option.title == '实时').num || 0.96
              }
              p = showOption.list
                ?.find(option => option.title == '实时')
                .manuscriptList[pageIdx].textAreas?.find(i => i.id == item.id)
            }
          })
          if (p) {
            let y = (p.textAreaRegion.y * num).toFixed(0) - 0
            document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop =
              this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 30
          }
          if (this.$refs.showImgContent) {
            this.$refs.showImgContent.isChangePage = false
            this.$refs.showImgContent.scrollIndex = showOptionIndex
          }
          /*           if (
            this.$refs.centerButtonList &&
            this.$refs.centerButtonList.checkSyncTabs.findIndex(item => item == '实时') == -1
          ) {
            this.$refs.centerButtonList.checkSyncTabs.push('实时')
          } */
          clearTimeout(timeOut)
        }, 1000)
        const timeOut2 = setTimeout(() => {
          clearTimeout(timeOut2)
        }, 1500)
      }
    },
    searchTextList(data) {
      const { type, keyword } = data
      this.getTextList(type, keyword)
    },
    pageOptionChange(value) {
      this.showOptions.forEach(showOption => {
        if (showOption.list?.find(option => option.title == value)) {
          showOption.list?.forEach(option => {
            if (option.title == value) {
              option.checked = true
            } else {
              option.checked = false
            }
          })
        }
      })
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.needChangeScroll = true
      }
      this.$nextTick(() => {
        if (this.$refs.optionList) {
          this.optionListWidth = this.$refs.optionList.clientWidth
        }
        if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
        this.getShowImgListPostions()
        this.getPageListPostions()
        //暂时取消切换预览标签自动同步功能，等人员反馈
        /*         if (this.$refs.centerButtonList) {
          this.$refs.centerButtonList.changeCheckSyncTabs()
        } */
      })
    },
    changeVideoWidth(width) {
      this.imagePlayWidth = width
    },
    prevDiff() {
      if (this.selectedDiffIdx) {
        let index = this.selectedDiffIdx.split('-')[0]
        let i = this.selectedDiffIdx.split('-')[1]
        this.diffs?.forEach((item, itemIndex) => {
          item.rectangles
            ?.filter(file => !file.isShow)
            .forEach((file, fileI) => {
              if (index == itemIndex && i > 0 && i == fileI) {
                /* this.selectedDiffIdx = itemIndex + '-' + (fileI - 1) */
                this.changeSelectDiff({ item, file, index: itemIndex, i: fileI - 1 })
              } else if (index == itemIndex && i == 0 && i == fileI) {
                if (index > 0) {
                  /* this.selectedDiffIdx = index - 1 + '-' + (arr.length - 1) */
                  let pageIdx = 0
                  let idxs = []
                  this.diffs.forEach((dItem, dItemIndex) => {
                    if (dItem.rectangles) {
                      idxs.push(dItemIndex)
                    }
                  })
                  if (idxs.length) {
                    pageIdx = idxs.findIndex(idx => idx == index)
                    if (pageIdx > 0) pageIdx = idxs[pageIdx - 1]
                  }
                  let arr = this.diffs[pageIdx].rectangles?.filter(file => !file.isShow)
                  let key = 'diff-' + pageIdx
                  this.$refs.contentList.activeKey = [...this.$refs.contentList.activeKey, key]
                  this.changeSelectDiff({ item, file, index: pageIdx, i: arr.length - 1 })
                }
              }
            })
        })
      }
    },
    nextDiff() {
      if (this.selectedDiffIdx) {
        if (this.selectedDiffIdx) {
          let index = this.selectedDiffIdx.split('-')[0]
          let i = this.selectedDiffIdx.split('-')[1]
          this.diffs?.forEach((item, itemIndex) => {
            item.rectangles
              ?.filter(file => !file.isShow)
              .forEach((file, fileI) => {
                if (index == itemIndex && i < item.rectangles?.filter(file => !file.isShow).length - 1 && i == fileI) {
                  this.selectedDiffIdx = itemIndex + '-' + (fileI + 1)
                  this.changeSelectDiff({ item, file, index: itemIndex, i: fileI + 1 })
                } else if (
                  index == itemIndex &&
                  i == item.rectangles?.filter(file => !file.isShow).length - 1 &&
                  i == fileI
                ) {
                  if (index < this.diffs.filter(item => item.rectangles).length - 1) {
                    /* this.selectedDiffIdx = index - 0 + 1 + '-' + (arr.length - 1) */
                    let pageIdx = 0
                    this.diffs.forEach((dItem, dItemIndex) => {
                      if (dItem.rectangles) {
                        pageIdx++
                        if (pageIdx == index - 0 + 1) {
                          pageIdx = dItemIndex
                        }
                      }
                    })
                    let key = 'diff-' + pageIdx
                    this.$refs.contentList.activeKey = [...this.$refs.contentList.activeKey, key]
                    this.changeSelectDiff({ item, file, index: pageIdx, i: 0 })
                  }
                }
              })
          })
        }
      }
    },
    changeCheckedStages(checkedStages) {
      this.checkedStages = checkedStages
    },
    changeHasLocal(hasLocal) {
      this.hasLocal = hasLocal
    },
    changeRadioValue(radioValue) {
      this.radioValue = radioValue
    },
    showDiff() {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.$forceUpdate()
      }
    },
    showImgHandleImgClick() {
      this.$nextTick(() => {
        Array.from(document.getElementsByClassName('show-local-content')).forEach(d => {
          Array.from(d.getElementsByTagName('img')).forEach(img => {
            img.addEventListener('click', e => {
              this.handleImgClick(e)
            })
          })
        })
      })
    },
    handleImgClick(e) {
      e.stopPropagation()
      if (this.$refs.videoModal) {
        this.$refs.videoModal.open(e.target.src)
        this.isOpenVideo = true
      }
    },
    videCancel() {
      this.isOpenVideo = false
    },
    downLoadImg(type) {
      this.selectLocalId = -1
      this.allLoadding = true
      this.loaddingText = '图片生成中...'
      this.isDownLoad = true
      this.scrollSync = true
      let showOptionIndex = -1
      let downLoadOption = null
      this.showOptions.forEach((showOption, index) => {
        if (showOption.list.some(option => option.isLatest)) {
          showOption.list.forEach(option => {
            if (option.isLatest) {
              option.checked = true
              showOptionIndex = index
              downLoadOption = option
            } else {
              option.checked = false
            }
          })
        }
      })
      if (!downLoadOption) return
      this.$nextTick(() => {
        if (document.getElementById('show-img-bottom-' + showOptionIndex))
          document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop = 0
      })
      const timeOut = setTimeout(() => {
        const zip = new JSZip()
        const promises = []
        const cache = []
        if (type == 'PDF') {
          let index = 0
          let showNodes = []
          let removeNodes = []
          const element = document.getElementById('show-img-bottom-' + showOptionIndex).childNodes[0]
          Array.from(
            document.getElementById('show-img-bottom-' + showOptionIndex).getElementsByClassName('show-page-img')
          ).forEach((img, imgIdx) => {
            let dom = document
              .getElementById('show-img-bottom-' + showOptionIndex)
              .getElementsByClassName('show-page-content')[imgIdx]
            showNodes.push({ img: img.cloneNode(true), dom: dom.cloneNode(true) })
            removeNodes.push({ img, dom })
          })

          removeNodes.forEach(item => {
            item.img.parentNode.removeChild(item.img)
            item.dom.parentNode.removeChild(item.dom)
          })
          removeNodes = []
          let PDF = new JsPDF('', 'pt', 'a4')
          this.nextStepPDF(
            index,
            element,
            zip,
            cache,
            promises,
            PDF,
            showNodes,
            removeNodes,
            downLoadOption,
            showOptionIndex
          )
        } else {
          let index = 0
          let showNodes = []
          let removeNodes = []
          const element = document.getElementById('show-img-bottom-' + showOptionIndex).childNodes[0]
          Array.from(
            document.getElementById('show-img-bottom-' + showOptionIndex).getElementsByClassName('show-page-img')
          ).forEach((img, imgIdx) => {
            let dom = document
              .getElementById('show-img-bottom-' + showOptionIndex)
              .getElementsByClassName('show-page-content')[imgIdx]
            showNodes.push({ img: img.cloneNode(true), dom: dom.cloneNode(true) })
            removeNodes.push({ img, dom })
          })
          removeNodes.forEach(item => {
            item.img.parentNode.removeChild(item.img)
            item.dom.parentNode.removeChild(item.dom)
          })
          removeNodes = []
          this.nextStep(index, element, zip, cache, promises, showNodes, removeNodes, downLoadOption, showOptionIndex)
        }
        clearTimeout(timeOut)
      }, 1000)
    },
    nextStep(idx, element, zip, cache, promises, showNodes, removeNodes, downLoadOption, showOptionIndex) {
      let item = downLoadOption.manuscriptList[idx]
      let arr = []
      let changeWidth = 0
      item.annotations?.forEach(local => {
        arr.push(local.position.width)
      })

      if (arr.length) {
        arr = arr.sort((a, b) => b - a)
        changeWidth = 0
        if (arr[0] * downLoadOption.num > item.imgWidth) {
          changeWidth = (arr[0] * downLoadOption.num - item.imgWidth).toFixed(0) - 0
        }
      }
      item.width = item.imgWidth
      item.height = item.imgHeight
      if (idx) {
        removeNodes.forEach(item => {
          item.img.parentNode.removeChild(item.img)
          item.dom.parentNode.removeChild(item.dom)
        })
      }
      document.getElementById('show-img-bottom-' + showOptionIndex).childNodes[0].appendChild(showNodes[idx].img)
      document
        .getElementById('show-img-bottom-' + showOptionIndex)
        .getElementsByClassName('show-content')[0]
        .appendChild(showNodes[idx].dom)
      let pushHeight = 0
      let sortPushHeights = []
      if (showNodes[idx].dom.childNodes && showNodes[idx].dom.childNodes.length) {
        Array.from(showNodes[idx].dom.childNodes).forEach(d => {
          if (d.tagName == 'IMG') {
            sortPushHeights.push(d.style.top.split('px')[0] - 0 + d.clientHeight)
          }
        })
      }
      if (sortPushHeights.length) {
        sortPushHeights = sortPushHeights.sort((a, b) => b - a)
        if (sortPushHeights[0] > item.height) {
          pushHeight = sortPushHeights[0]
        }
      }
      removeNodes = [{ img: showNodes[idx].img, dom: showNodes[idx].dom }]
      domtoimage
        .toJpeg(element, {
          bgcolor: '#fff',
          width: item.width + changeWidth,
          height: pushHeight ? pushHeight : item.height
          /* filter: filter */
        })
        .then(image => {
          const promise = this.getFile(image).then(data => {
            // 下载文件, 并存成blob对象
            let index = idx ? idx - 1 : idx
            let file_name = downLoadOption.manuscriptList[index].fileName
              .replaceAll('.psd', '.jpg')
              .replaceAll('.PSD', '.jpg') // 获取文件名(因为重复的文件名只会下载一个，故需要加入下标 不同名)
            for (let key in cache) {
              if (key == file_name) {
                index++
              }
            }
            file_name = downLoadOption.manuscriptList[index].fileName
              .replaceAll('.psd', '.jpg')
              .replaceAll('.PSD', '.jpg')
            zip.file(file_name, data, { binary: true }) // 逐个添加文件
            cache[file_name] = data
          })
          promises.push(promise)
          if (promises.length == downLoadOption.manuscriptList.length) {
            Promise.all(promises).then(() => {
              zip
                .generateAsync({ type: 'blob' })
                .then(content => {
                  let productOrder = this.taskDetail.productOrder + ''
                  let chapterOrder = this.taskDetail.chapterOrder + ''
                  if (productOrder.length == 1) {
                    productOrder = '00' + productOrder
                  } else if (productOrder.length == 2) {
                    productOrder = '0' + productOrder
                  }
                  if (chapterOrder.length == 1) {
                    chapterOrder = '00' + chapterOrder
                  } else if (chapterOrder.length == 2) {
                    chapterOrder = '0' + chapterOrder
                  }
                  const fileName = productOrder + '-' + chapterOrder
                  // 生成二进制流   然后保存文件（如果这个下载不了 也可以将下方这一行换成a标签下载逻辑）
                  FileSaver.saveAs(content, fileName + '.zip') // 利用file-saver保存文件  自定义文件名
                })
                .then(() => {
                  setTimeout(() => {
                    window.location.reload()
                  }, 100)
                })
              removeNodes.forEach(node => {
                node.img.parentNode.removeChild(node.img)
                node.dom.parentNode.removeChild(node.dom)
              })
              showNodes.forEach(node => {
                document.getElementById('show-img-bottom-' + showOptionIndex).childNodes[0].appendChild(node.img)
                document
                  .getElementById('show-img-bottom-' + showOptionIndex)
                  .getElementsByClassName('show-content')[0]
                  .appendChild(node.dom)
              })

              this.allLoadding = false
              this.isDownLoad = false
              this.loaddingText = '加载中...'
            })
          } else {
            idx++
            this.nextStep(idx, element, zip, cache, promises, showNodes, removeNodes, downLoadOption, showOptionIndex)
          }
        })
    },
    nextStepPDF(idx, element, zip, cache, promises, PDF, showNodes, removeNodes, downLoadOption, showOptionIndex) {
      let item = downLoadOption.manuscriptList[idx]
      let arr = []
      let changeWidth = 0
      item.annotations?.forEach(local => {
        arr.push(local.position.width)
      })
      if (arr.length) {
        arr = arr.sort((a, b) => b - a)
        changeWidth = 0
        if (arr[0] * downLoadOption.num > item.imgWidth) {
          changeWidth = (arr[0] * downLoadOption.num - item.imgWidth).toFixed(0) - 0
        }
      }
      item.width = item.imgWidth
      item.height = item.imgHeight
      if (idx) {
        removeNodes.forEach(item => {
          item.img.parentNode.removeChild(item.img)
          item.dom.parentNode.removeChild(item.dom)
        })
      }
      document.getElementById('show-img-bottom-' + showOptionIndex).childNodes[0].appendChild(showNodes[idx].img)
      document
        .getElementById('show-img-bottom-' + showOptionIndex)
        .getElementsByClassName('show-content')[0]
        .appendChild(showNodes[idx].dom)
      let pushHeight = 0
      let sortPushHeights = []
      if (showNodes[idx].dom.childNodes && showNodes[idx].dom.childNodes.length) {
        Array.from(showNodes[idx].dom.childNodes).forEach(d => {
          if (d.tagName == 'IMG') {
            sortPushHeights.push(d.style.top.split('px')[0] - 0 + d.clientHeight)
          }
        })
      }
      if (sortPushHeights.length) {
        sortPushHeights = sortPushHeights.sort((a, b) => b - a)
        if (sortPushHeights[0] > item.height) {
          pushHeight = sortPushHeights[0]
        }
      }
      /*       console.log(pushHeight,item.height,item)
      debugger */
      removeNodes = [{ img: showNodes[idx].img, dom: showNodes[idx].dom }]
      setTimeout(() => {
        const promise = domtoimage
          .toJpeg(element, {
            bgcolor: '#fff',
            width: item.width + changeWidth,
            height: pushHeight ? pushHeight : item.height
            /* filter */
          })
          .then(image => {
            /* const image = canvas.toDataURL('image/jpeg') */
            let contentWidth = item.width + changeWidth
            let contentHeight = pushHeight ? pushHeight : item.height
            if (contentHeight < item.width) {
              PDF.addPage([item.width + changeWidth, pushHeight ? pushHeight : item.height], 'landscape')
            } else {
              PDF.addPage([item.width + changeWidth, pushHeight ? pushHeight : item.height])
            }
            PDF.addImage(image, 'JPEG', 0, 0, contentWidth, contentHeight)
            promises.push(promise)
            if (promises.length == downLoadOption.manuscriptList.length) {
              PDF.deletePage(1)
              PDF.save(this.taskDetail.productionName + '-' + this.taskDetail.chapterOrder + '.pdf')
              removeNodes.forEach(node => {
                node.img.parentNode.removeChild(node.img)
                node.dom.parentNode.removeChild(node.dom)
              })
              showNodes.forEach(node => {
                document.getElementById('show-img-bottom-' + showOptionIndex).childNodes[0].appendChild(node.img)
                document
                  .getElementById('show-img-bottom-' + showOptionIndex)
                  .getElementsByClassName('show-content')[0]
                  .appendChild(node.dom)
              })
              this.allLoadding = false
              this.isDownLoad = false
              this.loaddingText = '加载中...'
              setTimeout(() => {
                window.location.reload()
              }, 100)
            } else {
              idx++
              this.nextStepPDF(
                idx,
                element,
                zip,
                cache,
                promises,
                PDF,
                showNodes,
                removeNodes,
                downLoadOption,
                showOptionIndex
              )
            }
          })
      }, 500)
    },
    async saveOldLocal(data) {
      let params = [
        {
          id: data.item.id || null,
          position: data.item.position,
          commentData: [
            {
              id:
                data.item.commentData && data.item.commentData[0] && data.item.commentData[0].id
                  ? data.item.commentData[0].id
                  : null,
              content: data.value,
              productionchapterPageAnnotationId: data.item.id || null
            }
          ],
          size: data.item.size,
          chapterId: this.taskDetail.chapterId,
          pageId: data.item.pageId,
          productionId: this.taskDetail.productionId,
          around: data.type
        }
      ]
      const res = await postAction('/annotation/edit_annotation', params)
      if (res.code == 200) {
        /* this.$message.success('保存成功') */
        if (data.type == 'left') {
          let list = this.leftAnnotationList
          list.forEach((page, pageIdx) => {
            if (page.pageId == data.item.pageId) {
              if (page.annotationList.every(i => i.id != res.data[0].id)) {
                page.annotationList.push({ ...res.data[0], selected: 1 })
                let deleteIdx = -1
                page.annotationList.forEach((a, aIdx) => {
                  if (!a.id) {
                    deleteIdx = aIdx
                  }
                })
                if (deleteIdx > -1) {
                  page.annotationList.splice(deleteIdx, 1)
                }
              } else {
                page.annotationList.forEach((i, aIdx) => {
                  if (i.id == res.data[0].id) {
                    list[pageIdx].annotationList[aIdx] = Object.assign({}, { ...res.data[0], selected: 1 })
                  }
                })
              }
            }
          })
          this.leftAnnotationList = JSON.parse(JSON.stringify(list))
        } else {
          let list = this.rightAnnotationList
          list.forEach((page, pageIdx) => {
            if (page.pageId == data.item.pageId) {
              if (page.annotationList.every(i => i.id != res.data[0].id)) {
                page.annotationList.push({ ...res.data[0], selected: 1 })
                let deleteIdx = -1
                page.annotationList.forEach((a, aIdx) => {
                  if (!a.id) {
                    deleteIdx = aIdx
                  }
                })
                if (deleteIdx > -1) {
                  page.annotationList.splice(deleteIdx, 1)
                }
              } else {
                page.annotationList.forEach((i, aIdx) => {
                  if (i.id == res.data[0].id) {
                    list[pageIdx].annotationList[aIdx] = Object.assign({}, { ...res.data[0], selected: 1 })
                  }
                })
              }
            }
          })
          this.rightAnnotationList = JSON.parse(JSON.stringify(list))
        }
        this.showAnnotationList.forEach(page => {
          if (page.pageId == data.item.pageId) {
            page.annotationList.forEach((i, idx) => {
              if (i.id == res.data[0].id) {
                page.annotationList[idx] = res.data[0]
              }
            })
          }
        })
        this.showAnnotationList.sort((a, b) => a.idx - b.idx)
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    async anTranslate(data) {
      if (this.$refs.contentList) {
        let dom = document.getElementById(data.page.id + '-' + data.item.id + '-' + data.an.id)
        let option = this.showOptions
          .find(showOption => showOption.list.find(option => option.title == this.$refs.contentList.selectedTitle))
          .list.find(option => option.title == this.$refs.contentList.selectedTitle)
        let arr = [
          {
            sourceLang: this.$refs.contentList.sourceLang == '自动检测' ? null : this.$refs.contentList.sourceLang,
            targetLang: this.$refs.contentList.targetLang,
            id: data.an.id,
            content: dom.innerText
          }
        ]
        const res = await postAction('/finish/manuscript/annotation/translate', arr)
        if (res.code == 200) {
          option.manuscriptList.forEach(page => {
            page.annotations?.forEach(item => {
              item.commentData?.forEach(an => {
                res.data.forEach(i => {
                  if (i.id == an.id) {
                    an.machineTranslation = i.machineTranslation
                  }
                })
              })
            })
          })
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    translateAll() {
      this.$nextTick(async () => {
        if (this.$refs.contentList) {
          let option = this.showOptions
            .find(showOption => showOption.list.find(option => option.title == this.$refs.contentList.selectedTitle))
            .list.find(option => option.title == this.$refs.contentList.selectedTitle)
          if (option) {
            let pushArr = []
            option.manuscriptList.forEach(page => {
              page.annotations?.forEach(item => {
                item.commentData?.forEach(an => {
                  let dom = document.getElementById(page.id + '-' + item.id + '-' + an.id)
                  if (!dom) return
                  pushArr.push({
                    sourceLang:
                      this.$refs.contentList.sourceLang == '自动检测' ? null : this.$refs.contentList.sourceLang,
                    targetLang: this.$refs.contentList.targetLang,
                    id: an.id,
                    content: dom.innerText
                  })
                })
              })
            })
            this.$refs.contentList.translateLoading = true
            const res = await postAction('/finish/manuscript/annotation/translate', pushArr)
            if (res.code == 200) {
              option.manuscriptList.forEach(page => {
                page.annotations?.forEach(item => {
                  item.commentData?.forEach(an => {
                    res.data.forEach(i => {
                      if (i.id == an.id) {
                        an.machineTranslation = i.machineTranslation
                      }
                    })
                  })
                })
              })
              this.$refs.contentList.translateVisible = false
              this.$refs.contentList.translateLoading = false
            } else {
              this.$message.error(res.msg)
            }
          }
        }
      })
    },
    async changeMachineStatus(local, item, itemIndex, option, editId, an) {
      if (!an.machineStatus || an.machineStatus == 2) {
        an.machineStatus = 1
      } else if (an.machineStatus == 1) {
        an.machineStatus = 2
      }
      let params = {
        id: local.id || null,
        finishManuscriptId: item.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        imageUrl: local.imageUrl || '',
        position: local.position,
        injuryType: local.injuryType || 0,
        injuryReason: local.injuryReason || '',
        annotationKind: local.annotationKind || 1,
        targetStage: local.targetStage,
        createStage: local.createStage || this.taskDetail.stageName,
        toStagesAnnotation: local.toStagesAnnotation,
        contentData: {
          content: an.content,
          id: editId || null,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar,
          machineTranslation: an.machineTranslation,
          machineStatus: an.machineStatus || 2
        }
      }
      this.isAutoSave = true
      if (params.id && (params.id + '').indexOf('newLocal') > -1) params.id = null
      const res = await postAction('/finish/manuscript/annotation', params)
      if (res.code == 200) {
        this.isAutoSave = false
        option.manuscriptList.find(p => p.id == res.data.id).annotations = res.data.annotations
        if (local.id) {
          option.manuscriptList.find(p => p.id == res.data.id).annotations.find(l => l.id == local.id).selected = 1
        }
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        if (local.targetStage) {
          this.allStages.push(local.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        if (this.$refs.contentList) {
          this.$refs.contentList.localValue = ''
          let timeOut = setTimeout(
            () => {
              this.$refs.contentList.editorOnsize()
              clearTimeout(timeOut)
            },
            editId ? 500 : 0
          )
        }
        this.$message.success('保存成功')
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    async changeUpdateStatus(item) {
      if (!item.updateStatus || item.updateStatus == 2) {
        item.updateStatus = 1
      } else if (item.updateStatus == 1) {
        item.updateStatus = 2
      }
      const res = await getAction('/finish/manuscript/annotation/updateStatus', {
        updateStatus: item.updateStatus,
        id: item.id
      })
      if (res.code == 200) {
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.$forceUpdate()
      }
    },
    cancelErrorEdit() {
      let oldShowOptions = JSON.parse(JSON.stringify(this.oldShowOptions))
      oldShowOptions.forEach((oldShowOption, oldShowOptionIdx) => {
        oldShowOption.list?.forEach((oldOption, oldOptionIdx) => {
          if (
            this.showOptions[oldShowOptionIdx] &&
            this.showOptions[oldShowOptionIdx].list &&
            oldOption.title == this.showOptions[oldShowOptionIdx].list[oldOptionIdx].title
          ) {
            oldOption.scrollTop = this.showOptions[oldShowOptionIdx].list[oldOptionIdx].scrollTop
          }
        })
      })
      this.showOptions = JSON.parse(JSON.stringify(oldShowOptions))
    },
    async deleError(loacal, page) {
      this.isAutoSave = true
      if ((loacal.id + '').indexOf('newLocal') > -1) {
        let deleIdx = page.annotations.findIndex(l => l.id == loacal.id)
        page.annotations.splice(deleIdx, 1)
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(option => {
            option.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        this.$message.success('删除成功')
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.$forceUpdate()
      } else {
        const res = await deleteAction('/finish/manuscript/annotation?ids=' + loacal.id)
        if (res.code == 200) {
          let deleIdx = page.annotations.findIndex(l => l.id == loacal.id)
          page.annotations.splice(deleIdx, 1)
          this.allStages = []
          this.showOptions.forEach(showOption => {
            showOption.list?.forEach(option => {
              option.canvasObj = {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              }
              option.manuscriptList?.forEach(file => {
                file.annotations?.forEach(local => {
                  if (local.targetStage) {
                    this.allStages.push(local.targetStage)
                  }
                })
              })
            })
          })
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
          this.saveIndex++
          if (this.changeProcess[this.saveIndex]) {
            this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
          } else {
            this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
          }
          this.$message.success('删除成功')
          this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
          this.$forceUpdate()
        } else {
          this.$message.error(res.msg)
        }
      }

      this.isAutoSave = false
    },
    async deleContentLoacal(local, deleId) {
      this.isAutoSave = true
      const res = await deleteAction('/finish/manuscript/annotation/content?ids=' + deleId)
      this.isAutoSave = false
      if (res.code == 200) {
        let deleIdx = local.commentData.findIndex(an => an.id == deleId)
        local.commentData.splice(deleIdx, 1)
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        if (this.$refs.contentList) {
          this.$refs.contentList.localValue = ''
          let timeOut = setTimeout(
            () => {
              this.$refs.contentList.editorOnsize()
              clearTimeout(timeOut)
            },
            deleId ? 500 : 0
          )
        }
        this.$message.success('删除成功')
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    textBatchChange(value) {
      this.selectTextAreaData?.forEach(textArea => {
        textArea.characterSentences?.forEach(text => {
          text.fontSize = value
        })
        textArea.showTexts?.forEach(p => {
          p.texts?.forEach(text => {
            text.fontSize = value
          })
        })
      })
    },
    async changeError(local, item, itemIndex, option) {
      let params = {
        id: local.id || null,
        finishManuscriptId: item.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        imageUrl: local.imageUrl || '',
        position: local.position,
        injuryType: local.injuryType || 0,
        injuryReason: local.injuryReason || '',
        annotationKind: local.annotationKind || 1,
        targetStage: local.targetStage,
        createStage: local.createStage || this.taskDetail.stageName,
        contentData: local.commentData ? local.commentData[0] : null
      }
      if (local.annotationKind != 1) {
        params.toStagesAnnotation = local.toStagesAnnotation
      } else {
        params.toStagesLeaveMessage = local.toStagesLeaveMessage
      }
      this.isAutoSave = true
      this.contentEdit = false
      if (params.id && (params.id + '').indexOf('newLocal') > -1) params.id = null
      const res = await postAction('/finish/manuscript/annotation', params)
      if (res.code == 200) {
        this.isAutoSave = false
        local.edit = false
        option.manuscriptList.find(p => p.id == res.data.id).annotations = res.data.annotations
        if (local.id) {
          option.manuscriptList.find(p => p.id == res.data.id).annotations.find(l => l.id == local.id).selected = 1
          if (
            !option.manuscriptList.find(p => p.id == res.data.id).annotations.find(l => l.id == local.id).injuryReason
          ) {
            option.manuscriptList
              .find(p => p.id == res.data.id)
              .annotations.find(l => l.id == local.id).injuryReason = undefined
          }
        }
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        if (local.targetStage) {
          this.allStages.push(local.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(o => {
            o.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        if (this.$refs.contentList) {
          this.$refs.contentList.localValue = ''
          let timeOut = setTimeout(() => {
            this.$refs.contentList.editorOnsize()
            clearTimeout(timeOut)
          }, 500)
        }
        this.$message.success('保存成功')
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.$refs.showImgContent.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    saveContentLoacal: simpleDebounce(async function(local, item, itemIndex, option, editId) {
      this.saveContentLoacalSim(local, item, itemIndex, option, editId)
    }, 300),
    async saveContentLoacalSim(local, item, itemIndex, option, editId) {
      if (this.isAutoSave) return
      if (!local.position.y) local.position.y = 0
      let params = {
        id: local.id || null,
        finishManuscriptId: item.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        imageUrl: local.imageUrl || '',
        position: local.position,
        injuryType: local.injuryType || 0,
        injuryReason: local.injuryReason || '',
        annotationKind: local.annotationKind || 1,
        targetStage: local.targetStage,
        toStagesAnnotation: local.toStagesAnnotation,
        createStage: local.createStage || this.taskDetail.stageName,
        contentData: {
          content: this.$refs.contentList.localValue,
          id: editId || null,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
      }
      this.isAutoSave = true
      this.contentEdit = false
      if (params.id && (params.id + '').indexOf('newLocal') > -1) params.id = null
      const res = await postAction('/finish/manuscript/annotation', params)
      if (res.code == 200) {
        this.isAutoSave = false
        option.manuscriptList.find(p => p.id == res.data.id).annotations = res.data.annotations
        if (local.id) {
          option.manuscriptList.find(p => p.id == res.data.id).annotations.find(l => l.id == local.id).selected = 1
        }
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        if (local.targetStage) {
          this.allStages.push(local.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(o => {
            o.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        if (this.$refs.contentList) {
          this.$refs.contentList.localValue = ''
          let timeOut = setTimeout(
            () => {
              this.$refs.contentList.editorOnsize()
              clearTimeout(timeOut)
            },
            editId ? 500 : 0
          )
        }
        this.$message.success('保存成功')
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.$forceUpdate()
        this.showImgHandleImgClick()
      } else {
        this.$message.error(res.msg)
      }
    },
    saveContentLoacalTranslate: simpleDebounce(async function(local, item, itemIndex, option, editId, an) {
      this.saveContentLoacalTranslateSim(local, item, itemIndex, option, editId, an)
    }, 100),
    async saveContentLoacalTranslateSim(local, item, itemIndex, option, editId, an) {
      /*       let machineTranslation = ''
      let newDom = document.createElement('div')
      newDom.style.width = '0'
      newDom.style.height = '0'
      newDom.style.opacity = '0'
      newDom.innerHTML = this.$refs.contentList.localValue
      document.body.appendChild(newDom)
      machineTranslation = newDom.innerText || ''
      document.body.removeChild(newDom) */
      if (!local.position.y) local.position.y = 0
      let params = {
        id: local.id || null,
        finishManuscriptId: item.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        imageUrl: local.imageUrl || '',
        position: local.position,
        injuryType: local.injuryType || 0,
        injuryReason: local.injuryReason || '',
        annotationKind: local.annotationKind || 1,
        targetStage: local.targetStage,
        createStage: local.createStage || this.taskDetail.stageName,
        contentData: {
          content: an.content,
          id: editId || null,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar,
          machineTranslation: an.machineTranslation,
          machineStatus: 1
        }
      }
      if (local.annotationKind != 1) {
        params.toStagesAnnotation = local.toStagesAnnotation
      } else {
        params.toStagesLeaveMessage = local.toStagesLeaveMessage
      }
      this.isAutoSave = true
      this.contentEdit = false
      if (params.id && (params.id + '').indexOf('newLocal') > -1) params.id = null
      const res = await postAction('/finish/manuscript/annotation', params)
      if (res.code == 200) {
        this.isAutoSave = false
        option.manuscriptList.find(p => p.id == res.data.id).annotations = res.data.annotations
        if (local.id) {
          option.manuscriptList.find(p => p.id == res.data.id).annotations.find(l => l.id == local.id).selected = 1
          if (
            !option.manuscriptList.find(p => p.id == res.data.id).annotations.find(l => l.id == local.id).injuryReason
          ) {
            option.manuscriptList
              .find(p => p.id == res.data.id)
              .annotations.find(l => l.id == local.id).injuryReason = undefined
          }
        }
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        if (local.targetStage) {
          this.allStages.push(local.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(o => {
            o.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        if (this.$refs.contentList) {
          this.$refs.contentList.localValue = ''
          let timeOut = setTimeout(
            () => {
              this.$refs.contentList.editorOnsize()
              clearTimeout(timeOut)
            },
            editId ? 500 : 0
          )
        }
        this.$message.success('保存成功')
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    changeSelectLocalId(id) {
      this.selectLocalId = id
    },
    toLocalOld(data) {
      this.selectOldLocalId = data.item.id
      let selectedIndex = -1
      let selectedPage = null
      let selectedOption = null
      this.showOptions.forEach((showOption, showOptionIdx) => {
        showOption.list?.forEach(option => {
          if (option.title == data.title) {
            selectedIndex = showOptionIdx
            selectedOption = option
          }
        })
      })
      if (data.title == '实时') {
        this.rightAnnotationList.forEach((page, pageIdx) => {
          page.annotationList?.forEach(an => {
            if (an.id == data.item.id) {
              data.item.selected = 1
              an.selected = 1
              selectedPage = selectedOption.manuscriptList.find(m => m.id == page.annotationList[0].pageId)
            } else {
              an.selected = 0
            }
          })
        })
      } else if (data.title == '原稿') {
        this.leftAnnotationList.forEach((page, pageIdx) => {
          page.annotationList?.forEach(an => {
            if (an.id == data.item.id) {
              data.item.selected = 1
              an.selected = 1
              selectedPage = selectedOption.manuscriptList.find(m => m.id == page.annotationList[0].pageId)
            } else {
              an.selected = 0
            }
          })
        })
      }
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.$forceUpdate()
      }
      if (selectedPage) {
        let num = 1
        let changeChecked = false
        this.showOptions[selectedIndex].list.forEach(option => {
          if (option.title == selectedOption.title) {
            if (!option.checked) {
              changeChecked = true
            }
            option.checked = true
            num = option.num
          } else {
            option.checked = false
          }
        })
        if (changeChecked) {
          let timeOut1 = setTimeout(() => {
            this.getShowImgListPostions()
            this.getPageListPostions()
            clearTimeout(timeOut1)
          }, 500)
          this.saveScrollTop()
          let timeOut = setTimeout(() => {
            let top = data.item.position.y - 0
            document.getElementById('show-img-bottom-' + selectedIndex).scrollTop =
              selectedPage.imgTop - 203 + top * num
            clearTimeout(timeOut)
          }, 1000)
        } else {
          let top = data.item.position.y - 0
          document.getElementById('show-img-bottom-' + selectedIndex).scrollTop = selectedPage.imgTop - 203 + top * num
        }
      }
    },
    closeLocal(imgs) {
      if (imgs && this.$refs.videoModal) {
        imgs.forEach(url => {
          if (url == this.$refs.videoModal.url) {
            this.$refs.videoModal.handleCancel()
          }
        })
      }
    },
    toLocal(data) {
      let selectedIndex = -1
      let selectedPage = null
      let selectedOption = null
      this.selectLocalId = data.item.id
      this.showOptions.forEach((showOption, showOptionIdx) => {
        showOption.list?.forEach(option => {
          option.manuscriptList?.forEach(page => {
            page.annotations?.forEach(an => {
              if (an.id == data.item.id && option.title == data.selectedTitle) {
                an.selected = 1
                selectedIndex = showOptionIdx
                selectedPage = page
                selectedOption = option
                this.$refs.showImgContent.scrollIndex = showOptionIdx
                if (this.$refs.showImgContent && !data.item.edit) {
                  if (!an.injuryReason) an.injuryType = 0
                  this.$refs.showImgContent.selectLocalErrorTwo(an.injuryType || 0, an.injuryReason || undefined, an)
                }
              } else {
                an.selected = 0
              }
            })
          })
        })
      })
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.$forceUpdate()
        this.$refs.showImgContent.EditorOnsize()
      }
      if (selectedPage) {
        let num = 1
        let changeChecked = false
        this.showOptions[selectedIndex].list.forEach(option => {
          if (option.title == selectedOption.title) {
            if (!option.checked) {
              changeChecked = true
            }
            option.checked = true
            num = option.num
          } else {
            option.checked = false
          }
        })
        if (changeChecked) {
          let timeOut1 = setTimeout(() => {
            this.getShowImgListPostions()
            this.getPageListPostions()
            clearTimeout(timeOut1)
          }, 500)
          this.saveScrollTop()
          let timeOut = setTimeout(() => {
            let top = data.item.position.y - 0 + data.item.position.sortY
            document.getElementById('show-img-bottom-' + selectedIndex).scrollTop = selectedPage.imgTop + top * num - 20
            clearTimeout(timeOut)
          }, 1000)
        } else {
          let top = data.item.position.y - 0 + data.item.position.sortY
          document.getElementById('show-img-bottom-' + selectedIndex).scrollTop = selectedPage.imgTop + top * num - 20
        }
      }
    },
    selectDiff(data) {
      this.selectedDiffIdx = data.index + '-' + data.i
      this.showOptions.forEach(showOption => {
        showOption?.list.forEach(option => {
          option?.manuscriptList.forEach(item => {
            item.annotations?.forEach(l => {
              l.selected = 0
            })
          })
        })
      })
      this.selectLocalId = -1
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.localValue = ''
        this.$refs.showImgContent.localInjuryReason = undefined
        this.$refs.showImgContent.selectLocalErrorTwo(0)
        this.$refs.showImgContent.$forceUpdate()
      }
    },
    clearSelectedDiffIdxTwo() {
      this.selectedDiffIdx = ''
    },
    clearSelectedDiffIdx() {
      this.selectedDiffIdx = ''
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          option.manuscriptList?.forEach(item => {
            item.annotations?.forEach(i => {
              i.selected = 0
            })
          })
        })
      })
      this.selectLocalId = -1
    },
    changeSelectDiff(data) {
      this.selectLocalId = -1
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.localValue = ''
        this.$refs.showImgContent.localInjuryReason = undefined
        this.$refs.showImgContent.selectLocalErrorTwo(0)
        this.$refs.showImgContent.$forceUpdate()
      }
      this.selectedDiffIdx = data.index + '-' + data.i
      let diffData = null
      let diffIndex = -1
      let originIndex = -1
      let originData = null
      this.showOptions.forEach((showOption, showOptionIdx) => {
        showOption.list.forEach(option => {
          if (option.title == this.diffTitle) {
            diffData = option.manuscriptList[data.index]
            diffIndex = showOptionIdx
          }
          if (option.title == this.diffLeftTtile) {
            originData = option.manuscriptList[data.index]
            originIndex = showOptionIdx
          }
        })
      })
      if (diffData) {
        let num = 1
        let changeChecked = false
        let originChecked = true
        this.showOptions[diffIndex].list.forEach(option => {
          if (option.title == this.diffTitle) {
            if (!option.checked) {
              changeChecked = true
            }
            option.checked = true
            num = option.changeHeight
          } else {
            option.checked = false
          }
          if (option.title == this.diffLeftTtile && !option.checked) {
            originChecked = false
          }
        })
        if (changeChecked) {
          this.isToDiff = true
          let timeOut1 = setTimeout(() => {
            this.getShowImgListPostions()
            this.getPageListPostions()
            clearTimeout(timeOut1)
          }, 500)
          this.saveScrollTop()
          let timeOut = setTimeout(() => {
            let moveDom = document.getElementById(data.index + '-' + data.i + '-diff-item')
            if (moveDom) {
              let top = moveDom.style.top.replaceAll('px', '') - 0
              /* document.getElementById('show-img-bottom-' + diffIndex).scrollTop = diffData.imgTop - 173 + top */
              document.getElementById('show-img-bottom-' + diffIndex).scrollTop = originData.imgTop - 173 + top
              if (originChecked) {
                document.getElementById('show-img-bottom-' + originIndex).scrollTop = originData.imgTop - 173 + top
              }
              this.isToDiff = false
            }

            clearTimeout(timeOut)
          }, 1000)
        } else {
          this.isToDiff = true
          let moveDom = document.getElementById(data.index + '-' + data.i + '-diff-item')
          if (moveDom) {
            let top = moveDom.style.top.replaceAll('px', '') - 0
            document.getElementById('show-img-bottom-' + diffIndex).scrollTop = originData.imgTop - 173 + top
            if (originChecked) {
              document.getElementById('show-img-bottom-' + originIndex).scrollTop = originData.imgTop - 173 + top
            }
          }
          let timeOut1 = setTimeout(() => {
            this.getShowImgListPostions()
            this.getPageListPostions()
            clearTimeout(timeOut1)
          }, 500)
          this.saveScrollTop()
          let timeOut = setTimeout(() => {
            this.isToDiff = false
            clearTimeout(timeOut)
          }, 100)
        }
      }
    },
    changeTaskConfirmStatus(type) {
      this.taskConfirmStatus = type
    },
    changeShowOptions() {
      this.showOptions = Object.assign([], this.showOptions, this.showOptions)
    },
    async diff() {
      if (this.$refs.showImgContent) {
        let referenceImages = []
        let comparisonImages = []
        this.showOptions.forEach(showOption => {
          showOption.list.forEach(option => {
            if (option.title == this.$refs.showImgContent.diffData.leftTitle) {
              referenceImages = option.manuscriptList.map(item => {
                return { fileName: item.fileName, fileUrl: item.filePath }
              })
            }
            if (option.title == this.$refs.showImgContent.diffData.rightTitle) {
              comparisonImages = option.manuscriptList.map(item => {
                return { fileName: item.fileName, fileUrl: item.filePath }
              })
            }
          })
        })
        let params = {
          flag: this.$refs.showImgContent.diffData.flag,
          minimalRectangleSize: 50,
          pixelToleranceLevel: this.$refs.showImgContent.diffData.pixelToleranceLevel / 100,
          threshold: this.$refs.showImgContent.diffData.threshold,
          referenceImages,
          comparisonImages
        }
        this.allLoadding = true
        this.loaddingText = '对比中'
        const res = await postAction('/finish/manuscript/compare', params)
        this.allLoadding = false
        this.loaddingText = '加载中'
        this.selectedDiff = false
        if (res.code == 200) {
          if (res.data && res.data.length == 1 && !res.data[0].rectangles) {
            this.diffs = []
            this.diffTitle = this.$refs.showImgContent.diffData.rightTitle
            this.diffLeftTtile = this.$refs.showImgContent.diffData.leftTitle
            this.contentList[0].visible = true
            let item = this.contentList[0]
            if (this.$refs.contentList) {
              this.$refs.contentList.noDrag = false
            }
            if (item.visible && item.isFixed) {
              this.$nextTick(() => {
                const dom = document.getElementById('content-list-' + item.id)
                dom.style.position = 'relative'
                dom.style.left = '0'
                dom.style.top = '0'
                dom.style.zIndex = '1'
                dom.style.boxShadow = 'none'
                dom.style.borderRadius = '0'
                document.getElementsByClassName('fixed-sidebar')[0].appendChild(dom)
                if (this.$refs.optionList) {
                  this.optionListWidth = this.$refs.optionList.clientWidth
                }
                if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
                this.getShowImgListPostions()
                this.getPageListPostions()
              })
            }
          } else {
            this.diffs = res.data
            this.diffsTotal = 0
            this.diffs.forEach(item => {
              item.fileName = item.fileName.replaceAll('<br>', '\n')
              if (item.rectangles) {
                this.diffsTotal += item.rectangles.length || 0
              }
            })
            this.diffTitle = this.$refs.showImgContent.diffData.rightTitle
            this.diffLeftTtile = this.$refs.showImgContent.diffData.leftTitle
            this.contentList[0].visible = true
            let item = this.contentList[0]
            if (this.$refs.contentList) {
              this.$refs.contentList.noDrag = false
            }
            if (item.visible && item.isFixed) {
              this.$nextTick(() => {
                const dom = document.getElementById('content-list-' + item.id)
                dom.style.position = 'relative'
                dom.style.left = '0'
                dom.style.top = '0'
                dom.style.zIndex = '1'
                dom.style.boxShadow = 'none'
                dom.style.borderRadius = '0'
                document.getElementsByClassName('fixed-sidebar')[0].appendChild(dom)
                if (this.$refs.optionList) {
                  this.optionListWidth = this.$refs.optionList.clientWidth
                }
                if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
                this.getShowImgListPostions()
                this.getPageListPostions()
              })
            }
          }
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    syncChangeScroll(data) {
      const { e, showOptionIndex } = data
      let checkedIndex = -1
      if (this.$refs.pageListContent) {
        this.showOptions.forEach((showOption, index) => {
          if (showOption.list.some(option => option.title == this.$refs.pageListContent.optionTitle)) {
            checkedIndex = index
          }
        })
        if (showOptionIndex == checkedIndex) {
          let idx = 0
          this.showImgLeftListPostions.forEach((item, index) => {
            if (e.target.scrollTop >= item.top - 153) {
              idx = index
            }
          })
          this.changeSelectIdx(idx)
        }
      }
    },
    closeContentDarg(e, item) {
      if (item.isFixed) {
        const dom = document.getElementById('content-list-' + item.id)
        if (dom) {
          item.mouseX = dom.getClientRects()[0].x + 20
          item.mouseY = dom.getClientRects()[0].y - 36
          dom.style.position = 'absolute'
          dom.style.boxShadow = '2px 1px 6px 2px rgba(0, 0, 0, 0.2)'
          dom.style.borderRadius = '5px'
          document.getElementsByClassName('content-list-view')[0].appendChild(dom)
        }
        this.$nextTick(() => {
          if (this.$refs.optionList) {
            this.optionListWidth = this.$refs.optionList.clientWidth
          }
          if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
          this.getShowImgListPostions()
          this.getPageListPostions()
        })
      }
      item.visible = false
      item.zIndex = 999
      this.selectLocalId = -1
    },
    changeContentDarg(e, item) {
      item.contentDrag = true
      const contentList = JSON.parse(JSON.stringify(this.contentList))
      if (
        contentList.sort((a, b) => {
          return b.zIndex - a.zIndex
        })[0] == 800
      ) {
        item.zIndex++
      } else {
        item.zIndex =
          contentList.sort((a, b) => {
            return b.zIndex - a.zIndex
          })[0].zIndex + 1
      }
      if (item.isFixed) {
        item.mouseX = e.target.parentNode.getClientRects()[0].x + 20
        item.mouseY = e.target.parentNode.getClientRects()[0].y - 36
        e.target.parentNode.style.position = 'absolute'
        e.target.parentNode.style.boxShadow = '2px 1px 6px 2px rgba(0, 0, 0, 0.2)'
        e.target.parentNode.style.borderRadius = '5px'
        document.getElementsByClassName('content-list-view')[0].appendChild(e.target.parentNode)
      }
      this.dragContent = item
    },
    changeCheckedToStages(checkedToStages) {
      this.checkedToStages = checkedToStages
    },
    getProduction() {
      getAction('/production/get', {
        id: this.taskDetail.productionId
      }).then(({ data }) => {
        this.departName = data.departName
        this.taskDetail.departName = data.departName
        this.diffFilterString = ''
        if (this.taskDetail.departName.indexOf('原创部') > -1) {
          if (this.taskDetail.stageName.indexOf('勾线') > -1) {
            this.diffFilterString = '勾线'
          }
          if (this.taskDetail.stageName.indexOf('精草') > -1) {
            this.diffFilterString = '精草'
          }
          if (this.taskDetail.stageName.indexOf('建模') > -1) {
            this.diffFilterString = '建模'
          }
          if (this.taskDetail.stageName.indexOf('上色') > -1) {
            this.diffFilterString = '上色'
          }
          if (this.taskDetail.stageName.indexOf('后期') > -1) {
            this.diffFilterString = '后期'
          }
        }
        this.getDictList()
        this.getFontFamily(data.departId)
      })
    },
    async getDictList() {
      let dictType =
        'hy-cgys,hy-cgrs,ry-cgrs,ry-cgys,hy-cgcjsy,hy-cgcjrs,ry-cgcjys,ry-cgcjrs,ry-cgxt-zs,hy-cf,hy-ys,hy-rs,ry-cf,ry-ys,ry-rs'
      /* if (this.departName.indexOf('日语部') > -1) {
        dictType = 'ry-cf,ry-ys,ry-rs'
      } */
      const res = await getAction('/sys/dict/list', { pageNo: 1, pageSize: 50, dictCode: dictType })
      /*       const rest = await getAction('/sys/dict/list', {
        pageNo: 1,
        pageSize: 50,
        dictCode: 'szfh,tsfh,tsfh-jsfh,tsfh-jt,tsfh-bd,tsfh-xz'
      }) */
      if (res.code == 200) {
        if (res.data && res.data.records && res.data.records.length) {
          this.errorDictList = res.data.records
        }
      } else {
        this.$message.error(res.msg)
      }
      /*       if (rest.code == 200) {
        this.emjDictList = []
        if (rest.data && rest.data.records && rest.data.records.length) {
          rest.data.records.forEach(async item => {
            const restItem = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: item.id })
            if (restItem.code == 200) {
              this.emjDictList.push({
                header: item.dictName,
                children: [...restItem.data.records.filter(r => r.status).map(r => r.itemValue)]
              })
            }
          })
        }
      } else {
        this.$message.error(rest.msg)
      } */
    },
    getFile(url) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    closeDiffModal() {
      this.selectedDiff = false
      if (this.$refs.showImgContent) {
        let titles = []
        this.allOption
          .filter(option => option.title != '原稿' && option.title != '原稿JPG' && option.title != '实时')
          .forEach(option => {
            if (option.checked) titles.push(option.title)
          })
        if (titles.length > 1) {
          this.$refs.showImgContent.diffData = {
            pixelToleranceLevel: 15,
            threshold: 75,
            flag: 1,
            leftTitle: titles[0],
            rightTitle: titles[1]
          }
        } else if (titles.length == 1) {
          this.$refs.showImgContent.diffData = {
            pixelToleranceLevel: 15,
            threshold: 75,
            flag: 1,
            leftTitle: titles[0],
            rightTitle: titles[0]
          }
        }
      }
    },
    async getPermsission() {
      const res = await getAction('/sys/permission/getUserPermissionByToken/review?taskId=' + this.$route.params.taskId)
      if (res.code == 200) {
        this.authList = res.data.auth
      } else {
        this.$message.error(res.msg)
      }
    },
    changeSelectedDiff() {
      this.selectedDiff = !this.selectedDiff
      if (this.selectedDiff) {
        this.isAddAnnotation = false
        let dom = document.getElementById('diff-option')
        this.$nextTick(() => {
          let moveDom = document.getElementsByClassName('diff-model')[0]
          moveDom.style.left = dom.getClientRects()[0].x - 240 + 'px'
        })
      }
    },
    imgContentMouseover() {
      this.imgCenterMove = true
    },
    imgContentMouseout() {
      this.imgCenterMove = false
    },
    keyDown() {
      document.onkeydown = e => {
        if (this.isWindows) {
          if (e.ctrlKey && (e.key == 'z' || e.key == 'Z')) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) {
              e.preventDefault()
              this.$refs.showImgContent.undo()
            } else {
              e.preventDefault()
              this.backData()
            }
            /* console.log('撤回') */
          } else if (e.ctrlKey && (e.key == 'y' || e.key == 'Y')) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) {
              e.preventDefault()
              this.$refs.showImgContent.redo()
            } else {
              e.preventDefault()
              this.nextData()
            }
            /* console.log('重做') */
          } else if (e.key == 'n' || e.key == 'N') {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            if (!this.imgCenterMove) return
            if (this.$refs.centerButtonList) {
              e.preventDefault()
              this.$refs.centerButtonList.changeIsAddAnnotation()
            }
            /* console.log('留言') */
          } else if (e.key == 'w' || e.key == 'W') {
            if (!this.imgCenterMove || this.$refs.showImgContent.selectedPlatte) return
            if (this.$refs.centerButtonList) {
              e.preventDefault()
              this.$refs.centerButtonList.openPlatteModal()
            }
            /* console.log('重做') */
          } else if (e.ctrlKey && (e.key == 's' || e.key == 'S')) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            e.preventDefault()
            this.changeAutoSave()
            /* console.log('保存') */
          } /*  else if (e.ctrlKey && (e.key == 'h' || e.key == 'H')) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            if (this.$refs.centerButtonList) {
              e.preventDefault()
              this.$refs.centerButtonList.showTextAreaChange()
            }
          } */ else if (
            e.ctrlKey &&
            e.key == '+'
          ) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            if (this.$refs.videoModal && this.$refs.videoModal.visible) {
              e.preventDefault()
              document.getElementById('video-image').style.width =
                document.getElementById('video-image').clientWidth + 10 + 'px'
              if (document.getElementById('video-image').clientWidth + 10 > 1000) {
                this.imagePlayWidth = document.getElementById('video-image').clientWidth + 10 + 'px'
              }
            }
          } else if (e.ctrlKey && e.key == '-') {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            if (this.$refs.videoModal && this.$refs.videoModal.visible) {
              e.preventDefault()
              if (document.getElementById('video-image').clientWidth > 50) {
                document.getElementById('video-image').style.width =
                  document.getElementById('video-image').clientWidth - 10 + 'px'
              }
            }
          } else if (e.key == 'Delete') {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) {
              if (document.getElementById('platteEditInput')) return
              e.preventDefault()
              if (this.$refs.showImgContent.selectData) {
                let index = this.$refs.showImgContent.selectData.index
                let type = this.$refs.showImgContent.selectData.type
                if (index < 0) return
                if (type == 'pencil' || type == 'line') {
                  this.$refs.showImgContent.lines.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                } else if (type == 'arrow') {
                  this.$refs.showImgContent.arrows.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                } else if (type == 'rect') {
                  this.$refs.showImgContent.rects.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                } else if (type == 'ring') {
                  this.$refs.showImgContent.rings.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                } else if (type == 'text') {
                  this.$refs.showImgContent.texts.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                }
                this.showOptions.forEach(showOption => {
                  showOption.list?.forEach(option => {
                    if (option.isLatest) {
                      option.canvasObj.stage
                        .getStage()
                        .findOne('Transformer')
                        .detach()
                    }
                  })
                })
                this.$refs.showImgContent.$forceUpdate()
                this.$refs.showImgContent.selectData = null
              }
            }
          } else if (e.key == 'Escape') {
            if (this.selectedPlatte) {
              this.$refs.showImgContent.closePlatte()
            }
          } else if (e.key == 'ArrowUp') {
            /* console.log('上') */
            if (this.$refs.contentList && this.contentList[0].visible) {
              e.preventDefault()
              this.prevDiff()
            }
          } else if (e.key == 'ArrowDown') {
            /* console.log('下') */
            if (this.$refs.contentList && this.contentList[0].visible) {
              e.preventDefault()
              this.nextDiff()
            }
          }
        } else {
          if (e.metaKey && (e.key == 'z' || e.key == 'Z')) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) {
              e.preventDefault()
              this.$refs.showImgContent.undo()
            } else {
              e.preventDefault()
              this.backData()
            }
            /* console.log('撤回') */
          } else if (e.metaKey && (e.key == 'y' || e.key == 'Y')) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) {
              e.preventDefault()
              this.$refs.showImgContent.redo()
            } else {
              e.preventDefault()
              this.nextData()
            }

            /* console.log('重做') */
          } else if (e.key == 'n' || e.key == 'N') {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            if (!this.imgCenterMove) return
            if (this.$refs.centerButtonList) {
              e.preventDefault()
              this.$refs.centerButtonList.changeIsAddAnnotation()
            }
            /* console.log('留言') */
          } else if (e.key == 'w' || e.key == 'W') {
            if (!this.imgCenterMove || this.$refs.showImgContent.selectedPlatt) return
            if (this.$refs.centerButtonList) {
              e.preventDefault()
              this.$refs.centerButtonList.openPlatteModal()
            }
            /* console.log('重做') */
          } else if (e.metaKey && (e.key == 's' || e.key == 'S')) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            e.preventDefault()
            this.changeAutoSave()
            /* console.log('保存') */
          } /*  else if (e.metaKey && (e.key == 'h' || e.key == 'H')) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            if (this.$refs.centerButtonList) {
              e.preventDefault()
              this.$refs.centerButtonList.showTextAreaChange()
            }
          } */ else if (
            e.metaKey &&
            e.key == '+'
          ) {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            if (this.$refs.videoModal && this.$refs.videoModal.visible) {
              e.preventDefault()
              document.getElementById('video-image').style.width =
                document.getElementById('video-image').clientWidth + 10 + 'px'
              if (document.getElementById('video-image').clientWidth + 10 > 1000) {
                this.imagePlayWidth = document.getElementById('video-image').clientWidth + 10 + 'px'
              }
            }
          } else if (e.metaKey && e.key == '-') {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) return
            if (this.$refs.videoModal && this.$refs.videoModal.visible) {
              e.preventDefault()
              if (document.getElementById('video-image').clientWidth > 50) {
                document.getElementById('video-image').style.width =
                  document.getElementById('video-image').clientWidth - 10 + 'px'
              }
            }
          } else if (e.key == 'Backspace') {
            if (this.$refs.showImgContent && this.$refs.showImgContent.selectedPlatte) {
              if (document.getElementById('platteEditInput')) return
              e.preventDefault()
              if (this.$refs.showImgContent.selectData) {
                let index = this.$refs.showImgContent.selectData.index
                let type = this.$refs.showImgContent.selectData.type
                if (index < 0) return
                if (type == 'pencil' || type == 'line') {
                  this.$refs.showImgContent.lines.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                } else if (type == 'arrow') {
                  this.$refs.showImgContent.arrows.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                } else if (type == 'rect') {
                  this.$refs.showImgContent.rects.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                } else if (type == 'ring') {
                  this.$refs.showImgContent.rings.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                } else if (type == 'text') {
                  this.$refs.showImgContent.texts.splice(index, 1)
                  this.$refs.showImgContent.undoList.push({
                    lines: JSON.parse(JSON.stringify(this.$refs.showImgContent.lines)),
                    rects: JSON.parse(JSON.stringify(this.$refs.showImgContent.rects)),
                    arrows: JSON.parse(JSON.stringify(this.$refs.showImgContent.arrows)),
                    rings: JSON.parse(JSON.stringify(this.$refs.showImgContent.rings)),
                    texts: JSON.parse(JSON.stringify(this.$refs.showImgContent.texts))
                  })
                }
                this.showOptions.forEach(showOption => {
                  showOption.list?.forEach(option => {
                    if (option.isLatest) {
                      option.canvasObj.stage
                        .getStage()
                        .findOne('Transformer')
                        .detach()
                    }
                  })
                })
                this.$refs.showImgContent.$forceUpdate()
                this.$refs.showImgContent.selectData = null
              }
            }
          } else if (e.key == 'Escape') {
            if (this.selectedPlatte) {
              this.$refs.showImgContent.closePlatte()
            }
          } else if (e.key == 'ArrowUp') {
            /* console.log('上') */
            if (this.$refs.contentList && this.contentList[0].visible) {
              e.preventDefault()
              this.prevDiff()
            }
          } else if (e.key == 'ArrowDown') {
            /* console.log('下') */
            if (this.$refs.contentList && this.contentList[0].visible) {
              e.preventDefault()
              this.nextDiff()
            }
          }
        }
      }
      window.addEventListener('mousewheel', this.handleScroll, { passive: false })
    },
    handleScroll(e) {
      // 判断是不是按下ctrl键
      if (e.ctrlKey) {
        // 取消浏览器默认的放大缩小网页行为
        e.preventDefault()
        // 判断是向上滚动还是向下滚动
        if (this.$refs.videoModal && this.$refs.videoModal.visible) {
          if (e.deltaY > 0) {
            if (document.getElementById('video-image').clientWidth > 50) {
              document.getElementById('video-image').style.width =
                document.getElementById('video-image').clientWidth - 10 + 'px'
            }
          } else {
            document.getElementById('video-image').style.width =
              document.getElementById('video-image').clientWidth + 10 + 'px'
            if (document.getElementById('video-image').clientWidth + 10 > 1000) {
              this.imagePlayWidth = document.getElementById('video-image').clientWidth + 10 + 'px'
            }
          }
        } else {
          if (e.deltaY > 0) {
            if (e.y > 153) {
              if (this.$refs.centerButtonList) {
                let sliderValue = this.$refs.centerButtonList.sliderValue - 2
                if (sliderValue <= 0) sliderValue = 0
                this.$refs.centerButtonList.sliderHandledChange(sliderValue)
              }
            }
          } else {
            if (e.y > 153) {
              if (this.$refs.centerButtonList) {
                let sliderValue = this.$refs.centerButtonList.sliderValue + 2
                if (sliderValue >= 200) sliderValue = 200
                this.$refs.centerButtonList.sliderHandledChange(sliderValue)
              }
            }
          }
        }
      }
    },
    changeAutoSave() {
      if (this.isAutoSave) {
        this.isAutoSave = false
      } else {
      }
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    backData() {
      this.saveIndex--
      if (this.changeProcess[this.saveIndex]) {
        this.diffData(this.showOptions, this.changeProcess[this.saveIndex])
        this.showOptions = JSON.parse(JSON.stringify(this.changeProcess[this.saveIndex]))
        setTimeout(() => {
          this.showOptions.forEach((showOption, showOptionIndex) => {
            document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop = showOption.list.find(
              option => option.checked
            ).scrollTop
          })
        }, 0)
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
      } else {
        this.saveIndex++
        this.$message.error('没有可撤回操作')
      }
      this.getShowImgListPostions()
      this.getPageListPostions()
    },
    nextData() {
      this.saveIndex++
      if (this.changeProcess[this.saveIndex]) {
        this.diffData(this.showOptions, this.changeProcess[this.saveIndex])
        this.showOptions = JSON.parse(JSON.stringify(this.changeProcess[this.saveIndex]))
        setTimeout(() => {
          this.showOptions.forEach((showOption, showOptionIndex) => {
            document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop = showOption.list.find(
              option => option.checked
            ).scrollTop
          })
        }, 0)
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
      } else {
        this.saveIndex--
        this.$message.error('没有可重做操作')
      }
      this.getShowImgListPostions()
      this.getPageListPostions()
    },
    diffData(oldData, newData) {
      oldData.forEach(oldShowOption => {
        oldShowOption.list?.forEach(oldOption => {
          oldOption.manuscriptList?.forEach(oldPage => {
            newData.forEach(newShowOption => {
              newShowOption.list?.forEach(newOption => {
                newOption.manuscriptList?.forEach(async newPage => {
                  if (oldOption.title == newOption.title && newPage.id == oldPage.id) {
                    if (
                      newPage.annotations &&
                      oldPage.annotations &&
                      newPage.annotations.length != oldPage.annotations.length
                    ) {
                      if (newPage.annotations.length > oldPage.annotations.length) {
                        let na = newPage.annotations.filter(na => oldPage.annotations.every(oa => oa.id != na.id))
                        console.log('添加', na, newPage)
                        if (na.length) {
                          let params = {
                            finishManuscriptId: newPage.id,
                            productionId: this.taskDetail.productionId,
                            chapterId: this.taskDetail.chapterId,
                            mergeFinishManuscriptIds: newPage.id,
                            injuryReason: na[0].injuryReason,
                            imageUrl: na[0].imageUrl,
                            position: na[0].position,
                            annotationKind: na[0].annotationKind,
                            targetStage: na[0].targetStage,
                            createStage: na[0].createStage,
                            toStagesAnnotation: na[0].toStagesAnnotation,
                            commentData: na[0].commentData
                          }
                          postAction('/finish/manuscript/annotation', params)
                        }
                      } else {
                        let annotations = JSON.parse(JSON.stringify(oldPage.annotations))
                        annotations.sort((a, b) => a.id - b.id)
                        deleteAction('/finish/manuscript/annotation?ids=' + annotations[annotations.length - 1].id)
                        console.log('删除')
                      }
                    } /* else if(!newPage.annotation&&oldPage.annotations){
                      console.log('删除')
                    }else if(!oldPage.annotation&&newPage.annotations){
                      console.log('添加')
                    } */
                  }
                })
              })
            })
          })
        })
      })
    },
    changeShowImgListWidth(value) {
      this.showImgListWidth = value
      if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
      /*       this.showOptions.forEach((showOption, showOptionIndex) => {
        if (!document.getElementById('show-img-bottom-' + showOptionIndex)) return
        let scrollTop =
          (document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop / (oldWidth.split('%')[0] - 0)) * 100
        document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop =
          scrollTop * ((this.showImgListWidth.split('%')[0] - 0) / 100)
        if (showOption.list.find(option => option.checked)) {
          showOption.list.find(option => option.checked).scrollTop =
            scrollTop * ((this.showImgListWidth.split('%')[0] - 0) / 100)
          if (showOption.list.find(option => option.checked).num == 1) {
            showOption.list.find(option => option.checked).oldScroll =
              scrollTop * ((this.showImgListWidth.split('%')[0] - 0) / 100)
          }
        }
      }) */
      this.$refs.showImgContent.isShow = false
      let timeOut = setTimeout(() => {
        this.$refs.showImgContent.isShow = true
        clearTimeout(timeOut)
      }, 0)
      this.$nextTick(() => {
        this.showImgWidth = this.$refs.showImg.clientWidth
        this.optionListWidth = this.$refs.optionList.clientWidth
        this.getPageListPostions()
        this.getShowImgListPostions()
      })
    },
    saveLoacal: simpleDebounce(async function(local, item, itemIndex, option, annotationKind, isSaveValue) {
      this.saveLoacalSim(local, item, itemIndex, option, annotationKind, isSaveValue)
    }, 100),
    async saveLoacalSim(local, item, itemIndex, option, annotationKind, isSaveValue) {
      if (!local.position.y) local.position.y = 0
      let params = {
        id: local.id || null,
        finishManuscriptId: item.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        imageUrl: '',
        position: local.position,
        injuryType: this.$refs.showImgContent.localInjuryType || 0,
        injuryReason: this.$refs.showImgContent.localInjuryReason || '',
        annotationKind: annotationKind || 1,
        targetStage: local.targetStage,
        createStage: local.createStage || this.taskDetail.stageName,
        contentData: isSaveValue
          ? null
          : {
              content: this.$refs.showImgContent.localValue || '',
              userId: this.userInfo.id,
              username: this.userInfo.nickName,
              avatar: this.userInfo.userFile.avatar
            }
      }
      if (params.annotationKind == 1) {
        params.toStagesLeaveMessage = this.toStagesLeaveMessage
      } else {
        params.toStagesAnnotation = this.toStagesAnnotation
      }
      this.isAutoSave = true
      this.contentEdit = false
      if (params.id && (params.id + '').indexOf('newLocal') > -1) params.id = null
      const res = await postAction('/finish/manuscript/annotation', params)
      if (res.code == 200) {
        this.isAutoSave = false
        option.manuscriptList.find(p => p.id == res.data.id).annotations = res.data.annotations
        if ((local.id + '').indexOf('newLocal') == -1) {
          option.manuscriptList.find(p => p.id == res.data.id).annotations.find(l => l.id == local.id).selected = 1
        }
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        if (local.targetStage) {
          this.allStages.push(local.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(o => {
            o.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        if (this.$refs.showImgContent && !isSaveValue) {
          this.$refs.showImgContent.localValue = ''
          /*           this.$refs.showImgContent.localInjuryType = 0
          this.$refs.showImgContent.localInjuryReason = undefined */
        }
        this.$forceUpdate()
        this.$refs.showImgContent.EditorOnsize()
      } else {
        this.$message.error(res.msg)
      }
    },
    saveError: simpleDebounce(async function(local, item, itemIndex, option, annotationKind) {
      this.saveErrorSim(local, item, itemIndex, option, annotationKind)
    }, 100),
    async saveErrorSim(local, item, itemIndex, option, annotationKind) {
      if (!local.position.y) local.position.y = 0
      let params = {
        id: local.id || null,
        finishManuscriptId: item.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        imageUrl: '',
        position: local.position,
        injuryType: this.$refs.showImgContent.localInjuryType || 0,
        injuryReason: this.$refs.showImgContent.localInjuryReason || '',
        annotationKind: annotationKind || 1,
        targetStage: local.targetStage,
        createStage: local.createStage || this.taskDetail.stageName,
        toStagesAnnotation: this.toStagesAnnotation,
        contentData: {
          content: '',
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
      }
      this.isAutoSave = true
      if (params.id && (params.id + '').indexOf('newLocal') > -1) params.id = null
      const res = await postAction('/finish/manuscript/annotation', params)
      if (res.code == 200) {
        if (this.toStagesAnnotation.length) {
          this.targetStage = this.toStagesAnnotation[0].stageName
        } else {
          this.targetStage = ''
        }
        if (local.targetStage) {
          this.allStages.push(local.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        this.isAutoSave = false
        option.manuscriptList.find(p => p.id == res.data.id).annotations = res.data.annotations
        if ((local.id + '').indexOf('newLocal') == -1) {
          option.manuscriptList.find(p => p.id == res.data.id).annotations.find(l => l.id == local.id).selected = 1
        }
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(o => {
            o.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.$forceUpdate()
        this.$refs.showImgContent.EditorOnsize()
      } else {
        this.$message.error(res.msg)
      }
    },
    async saveDiffLoacal(diffItem, item, itemIndex, option, diffIndex) {
      let params = {
        id: null,
        finishManuscriptId: item.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        imageUrl: '',
        position: {
          x: diffItem.minPoint.x,
          y: diffItem.minPoint.y,
          width: diffItem.maxPoint.x - diffItem.minPoint.x,
          height: diffItem.maxPoint.y + 20 - diffItem.minPoint.y
        },
        injuryType: this.$refs.showImgContent.localInjuryType || 0,
        injuryReason: this.$refs.showImgContent.localInjuryReason || '',
        annotationKind: 3,
        targetStage: this.$refs.showImgContent.targetStage,
        createStage: this.taskDetail.stageName,
        toStagesAnnotation: this.toStagesAnnotation,
        contentData: {
          content: this.$refs.showImgContent.localValue,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
      }
      this.isAutoSave = true
      const res = await postAction('/finish/manuscript/annotation', params)
      if (res.code == 200) {
        if (this.toStagesAnnotation.length) {
          this.targetStage = this.toStagesAnnotation[0].stageName
        } else {
          this.targetStage = ''
        }
        if (this.$refs.showImgContent.targetStage) {
          this.allStages.push(this.$refs.showImgContent.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        this.isAutoSave = false
        option.manuscriptList.find(p => p.id == res.data.id).annotations = res.data.annotations
        option.manuscriptList
          .find(p => p.id == res.data.id)
          .annotations.forEach(an => {
            if (an.selected == 1) {
              this.selectLocalId = an.id
            }
          })
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(o => {
            o.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.localValue = ''
          /*           this.$refs.showImgContent.localInjuryType = 0
          this.$refs.showImgContent.localInjuryReason = undefined */
        }
        this.selectedDiffIdx = ''
        this.diffs.find(diff => diff.fileName == item.fileName).rectangles.splice(diffIndex, 1)
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.$forceUpdate()
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    async saveDiffError(diffItem, item, itemIndex, option, diffIndex) {
      let params = {
        id: null,
        finishManuscriptId: item.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        imageUrl: '',
        position: {
          x: diffItem.minPoint.x,
          y: diffItem.minPoint.y,
          width: diffItem.maxPoint.x - diffItem.minPoint.x,
          height: diffItem.maxPoint.y + 20 - diffItem.minPoint.y
        },
        injuryType: this.$refs.showImgContent.localInjuryType || 0,
        injuryReason: this.$refs.showImgContent.localInjuryReason || '',
        annotationKind: 3,
        targetStage: this.$refs.showImgContent.targetStage,
        createStage: this.taskDetail.stageName,
        toStagesAnnotation: this.toStagesAnnotation,
        contentData: {
          content: '',
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
      }
      this.isAutoSave = true
      const res = await postAction('/finish/manuscript/annotation', params)
      if (res.code == 200) {
        this.isAutoSave = false
        option.manuscriptList.find(p => p.id == res.data.id).annotations = res.data.annotations
        option.manuscriptList
          .find(p => p.id == res.data.id)
          .annotations.forEach(an => {
            if (an.selected == 1) {
              this.selectLocalId = an.id
            }
          })
        if (this.$refs.showImgContent.targetStage) {
          this.allStages.push(this.$refs.showImgContent.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(o => {
            o.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.selectedDiffIdx = ''
        this.diffs.find(diff => diff.fileName == item.fileName).rectangles.splice(diffIndex, 1)
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.$forceUpdate()
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    changeAnnotationList(data) {
      if (!data.option.manuscriptList[data.idx].annotations) data.option.manuscriptList[data.idx].annotations = []
      data.option.manuscriptList[data.idx].annotations?.forEach(item => {
        item.selected = 0
      })
      let newId = 'newLocal' + Math.round(Math.random() * 10000) + new Date().getTime()
      this.selectLocalId = newId
      data.option.manuscriptList[data.idx].annotations.push({
        position: data.position,
        size: 30,
        selected: 1,
        id: newId,
        commentData: {
          avatar: this.userInfo.avatar,
          content: '',
          userId: this.userInfo.id,
          username: this.userInfo.nickName
        },
        annotationKind: 1,
        createStage: this.taskDetail.stageName,
        targetStage:
          this.taskDetail.toStagesLeaveMessage && this.taskDetail.toStagesLeaveMessage.length
            ? this.taskDetail.toStagesLeaveMessage[0].stageName
            : '',
        toStagesLeaveMessage: this.taskDetail.toStagesLeaveMessage || []
      })
      this.selectedDiffIdx = ''
      this.selectLocalId = newId
      this.isAddAnnotation = false
    },
    changeIsAddAnnotation() {
      this.isAddAnnotation = !this.isAddAnnotation
      if (this.isAddAnnotation) {
        this.selectedDiff = false
      }
    },
    savePlatte: simpleDebounce(async function(sortY, sortX, endY) {
      this.savePlatteSim(sortY, sortX, endY)
    }, 100),
    savePlatteSim(sortY, sortX, endY) {
      this.showOptions.forEach((showOption, showOptionIndex) => {
        let option = showOption.list.find(option => option.checked)
        if (option.isLatest) {
          option.canvasObj.stage
            .getStage()
            .findOne('Transformer')
            .detach()
          let imgPositions = []
          option.manuscriptList.forEach(item => {
            let img = document.getElementById(item.id + 'showLeftImg')
            let i = JSON.parse(JSON.stringify(img.getClientRects()[0]))
            imgPositions.push(i)
          })
          let imgWidth = imgPositions[0].width || 0
          if (imgWidth < sortX) {
            this.stageSize.width = sortX + 20
          } else {
            this.stageSize.width = imgWidth
          }
          this.$nextTick(() => {
            let base64 = option.canvasObj.stage.getStage().toDataURL() || ''
            if (base64 != option.canvasObj.url) {
              const file = this.dataURLtoFile(base64, '画板-' + showOptionIndex)
              this.upload(file, option, showOptionIndex, sortY, sortX, endY)
            } else {
              this.$message.warning('批注内容为空')
              if (this.$refs.showImgContent) this.$refs.showImgContent.platteLoading = false
            }
          })
        }
      })
    },
    upload(file, option, showOptionIndex, sortY, sortX, endY) {
      this.tokenAxios({
        url: '/oss',
        method: 'get'
      }).then(res => {
        let policy = {
          policy: res.data.policy,
          signature: res.data.signature,
          ossaccessKeyId: res.data.accessid,
          dir: res.data.dir,
          host: res.data.host,
          key: `${res.data.dir}glxt/fatchStraw/${this.userInfo.realname}/${this.getGuid()}_${file.name.replace(
            /[^\u4e00-\u9fa5a-zA-Z0-9\w]/g,
            ''
          )}`
        }
        this.ossUploadData = policy
        this.ossFilePolicy[file.name] = policy
        let formData = new FormData()
        for (let key in policy) {
          formData.append(key, policy[key])
        }
        formData.append('file', file)
        postAction(policy.host, formData).then(rest => {
          console.log('成功OSS', policy.host)
          let params = this.getSaveFileParams(file)
          this.saveLocal(option, params.filePath, showOptionIndex, sortY, sortX, endY)
        })
      })
    },
    saveLocal: simpleDebounce(async function(option, url, showOptionIndex, sortY, sortX, endY) {
      this.saveLocalSim(option, url, showOptionIndex, sortY, sortX, endY)
    }, 100),
    async saveLocalSim(option, url, showOptionIndex, sortY, sortX, endY) {
      let imgPositions = []
      let pageNum = -1
      let finishManuscriptId = null
      let imgHeight = 0
      let scrollTop = document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop
      let mergeFinishManuscriptIds = []
      option.manuscriptList.forEach((item, itemIndex) => {
        if (
          scrollTop + sortY > item.imgTop &&
          option.manuscriptList[itemIndex + 1] &&
          scrollTop + sortY < option.manuscriptList[itemIndex + 1].imgTop
        ) {
          mergeFinishManuscriptIds.push(item.id)
        }
        if (
          scrollTop + endY > item.imgTop &&
          ((option.manuscriptList[itemIndex + 1] && scrollTop + endY < option.manuscriptList[itemIndex + 1].imgTop) ||
            itemIndex == option.manuscriptList.length - 1)
        ) {
          mergeFinishManuscriptIds.push(item.id)
        }
      })
      mergeFinishManuscriptIds = [...new Set(mergeFinishManuscriptIds)]
      option.manuscriptList.forEach(item => {
        let img = document.getElementById(item.id + 'showLeftImg')
        let i = JSON.parse(JSON.stringify(img.getClientRects()[0]))
        i.top = i.top - 0 + scrollTop
        imgPositions.push(i)
      })
      let imgWidth = imgPositions[0].width || 0

      imgPositions.forEach((item, index) => {
        /*         if (scrollTop + sortY * option.num >= item.top - 153) {
          pageNum = index
        } */
        if (scrollTop + sortY >= item.top - 153) {
          pageNum = index
        }
      })
      imgPositions.forEach((item, index) => {
        if (pageNum > index) {
          imgHeight += item.height
        }
      })
      if (pageNum > -1) {
        finishManuscriptId = option.manuscriptList[pageNum].id
      }
      let width = document.getElementById('show-img-bottom-' + showOptionIndex).clientWidth
      let cWidth = document.getElementById('show-img-bottom-' + showOptionIndex).firstChild.childNodes[0].clientWidth
      let changeX = (width - cWidth) / 2
      if (changeX > 0) {
        changeX = changeX / option.num
        changeX = 0 - changeX
      } else {
        changeX = 0
        width = cWidth
      }
      let sortNum = 0
      let sliderValue = 100
      if (imgWidth >= sortX) {
        sliderValue = 100
        sortX = imgWidth
      } else {
        sortNum = 20
        sliderValue = ((imgWidth / (sortX + 20)) * 100).toFixed(0) - 5
      }
      let params = {
        finishManuscriptId,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        injuryType: this.$refs.showImgContent.platteInjuryType || 0,
        mergeFinishManuscriptIds: mergeFinishManuscriptIds.length > 1 ? mergeFinishManuscriptIds.join(',') : '',
        injuryReason: this.$refs.showImgContent.platteInjuryInjuryReason || '',
        imageUrl: url,
        position: {
          width: (sortX + sortNum) / option.num,
          x: changeX,
          y: (scrollTop - imgHeight - 1) / option.num - 0,
          sortY: (sortY || 0) / option.num,
          sliderValue
        },
        annotationKind: 2,
        targetStage: this.$refs.showImgContent.targetStage,
        createStage: this.taskDetail.stageName,
        toStagesAnnotation: this.toStagesAnnotation,
        contentData: {
          content: this.$refs.showImgContent.platteValue,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
      }
      this.isAutoSave = true
      const res = await postAction('/finish/manuscript/annotation', params)
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.platteLoading = false
      }
      if (res.code == 200) {
        this.isAutoSave = false
        option.manuscriptList[pageNum].annotations = res.data.annotations
        this.saveIndex++
        option.canvasObj = {
          stageSize: { width: 0, height: 0 },
          stage: null,
          url: ''
        }
        if (this.$refs.showImgContent.targetStage) {
          this.allStages.push(this.$refs.showImgContent.targetStage)
          this.allStages = [...new Set(this.allStages)]
          /*           if (this.$refs.contentList) {
            this.$refs.contentList.checkedStages = this.allStages
          } */
        }
        this.showOptions.forEach(showOption => {
          showOption.list?.forEach(o => {
            o.canvasObj = {
              stageSize: { width: 0, height: 0 },
              stage: null,
              url: ''
            }
          })
        })
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
        }
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        if (this.$refs.showImgContent) this.$refs.showImgContent.closePlatte()
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    async getScrollTop() {
      const res = await getAction('/finish/roll', {
        chapterId: this.taskDetail.chapterId,
        userId: this.userInfo.id,
        type: this.$route.params.imgId == 0 ? 0 : 1,
        taskId: this.taskDetail.taskId
      })
      if (res.code == 200) {
        this.rollPositionArray = res.data.rollPositionArray || []
        this.saveScrollId = res.data.id
        this.getPageList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async saveScrollTop() {
      let arr = []
      this.showOptions.forEach((showOption, showOptionIndex) => {
        arr.push({ list: [] })
        showOption.list.forEach((option, optionIndex) => {
          arr[showOptionIndex].list.push({
            title: option.title,
            scrollTop: (option.scrollTop / (option.num || 1)).toFixed(2),
            value: showOptionIndex + '-' + optionIndex,
            checked: option.checked
          })
        })
      })
      let params = {
        chapterId: this.taskDetail.chapterId,
        userId: this.userInfo.id,
        rollPosition: JSON.stringify(arr),
        taskId: this.taskDetail.taskId
      }
      if (this.saveScrollId) params.id = this.saveScrollId
      await postAction('/finish/roll', params)
    },
    changePageListWidth() {
      if (this.pageListShow) {
        this.$refs.pageList.style.width = 0
      } else {
        this.$refs.pageList.style.width = '10%'
      }
      this.isChangePageList = true
      this.pageListShow = !this.pageListShow
      setTimeout(() => {
        if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
        this.$refs.showImgContent.isShow = false
        let timeOut = setTimeout(() => {
          this.$refs.showImgContent.isShow = true
          clearTimeout(timeOut)
        }, 0)
        this.$nextTick(() => {
          this.showImgWidth = this.$refs.showImg.clientWidth
          this.optionListWidth = this.$refs.optionList.clientWidth
          if (this.pageListShow) {
            this.getPageListPostions()
          }
          this.getShowImgListPostions()
          this.isChangePageList = false
        })
      }, 300)
    },
    changeShowScroll(e, idx) {
      let num = this.showImgLeftListPostions[this.selectIdx].width / e.target.width
      this.changeScrollHeihgt = (e.offsetY * num).toFixed(0) - 0
      this.selectIdx = idx
    },
    changeScrollSync(type) {
      this.scrollSync = type
      if (this.$refs.centerButtonList && this.$refs.showImgContent) {
        this.$refs.showImgContent.checkSyncTabs = this.$refs.centerButtonList.checkSyncTabs
      }
    },
    changeSelectIdx(idx) {
      this.changeScrollHeihgt = 0
      this.selectIdx = idx
    },
    changePage() {
      if (this.$refs.showImgContent) {
        this.$nextTick(() => {
          this.$refs.showImgContent.changePage(this.$refs.pageListContent.optionTitle)
        })
      }
    },
    getPageListPostions() {
      if (
        document.getElementsByClassName('page-item').length &&
        document.getElementsByClassName('page-item')[0].getClientRects()[0]
      ) {
        let scrollTop = document.getElementsByClassName('list-content')[0].scrollTop
        this.pageListPostions = Array.from(document.getElementsByClassName('page-item')).map((item, idx) => {
          let i = JSON.parse(JSON.stringify(item.getClientRects()[0]))
          i.top = i.top - 0 + scrollTop
          return {
            ...i,
            idx
          }
        })
      }
    },
    getShowImgListPostions() {
      let scrollTop = document.getElementsByClassName('show-img-bottom')[0].scrollTop
      let pageShowIndex = 0
      if (this.$refs.pageListContent) {
        this.showOptions.forEach((showOption, showOptionIdx) => {
          if (showOption.list.findIndex(option => option.title == this.$refs.pageListContent.optionTitle) > -1) {
            scrollTop = document.getElementById('show-img-bottom-' + showOptionIdx).scrollTop
            pageShowIndex = showOptionIdx
          }
        })
      }
      this.showImgLeftListPostions = Array.from(
        document.getElementById('show-img-bottom-' + pageShowIndex).getElementsByClassName('show-page-img')
      ).map((item, idx) => {
        let i = JSON.parse(JSON.stringify(item.getClientRects()[0]))
        for (let key in i) {
          i[key] = (i[key] - 0).toFixed(0)
        }
        i.top = i.top - 0 + scrollTop
        return {
          ...i,
          idx
        }
      })
      let selectIdx = -1
      this.showImgLeftListPostions.forEach((item, index) => {
        if (document.getElementById('show-img-bottom-' + pageShowIndex).scrollTop >= item.top - 153) {
          selectIdx = index
        }
      })
      if (selectIdx > -1) {
        this.changeSelectIdx(selectIdx)
      }
      let heightList = []
      this.showOptions.forEach((showOption, showOptionIndex) => {
        let loadImgs = []
        let scrollTop = document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop
        if (showOption.list.find(item => item.checked)) {
          showOption.list
            .find(item => item.checked)
            .manuscriptList?.forEach((item, itemIndex) => {
              let img = document.getElementById(item.id + 'showLeftImg')
              if (!img) return
              loadImgs.push(img)
              item.imgHeight = img.getClientRects()[0].height.toFixed(1) - 0
              item.imgX = img.getClientRects()[0].x.toFixed(1) - 0
              item.imgY = img.getClientRects()[0].y.toFixed(1) - 0
              item.imgTop = img.getClientRects()[0].top.toFixed(1) - 0 + scrollTop - 153
              item.imgWidth = img.getClientRects()[0].width.toFixed(1) - 0
              if (item.id == this.skipImgId && showOption.list.find(item => item.checked).isLatest) {
                this.skipItem = item
                selectIdx = itemIndex
              }
              if (loadImgs.length == showOption.list.find(option => option.checked).manuscriptList.length) {
                showOption.list.find(option => option.checked).allImgHeight = 0
                loadImgs.forEach(dom => {
                  showOption.list.find(option => option.checked).allImgHeight += dom.getClientRects()[0].height
                })
                showOption.list.find(option => option.checked).allImgHeight =
                  showOption.list.find(option => option.checked).allImgHeight.toFixed(1) - 0
                heightList.push(showOption.list.find(option => option.checked).allImgHeight)
                if (!showOption.list.find(option => option.checked).oldAllImgHeight) {
                  showOption.list.find(option => option.checked).oldAllImgHeight = showOption.list.find(
                    option => option.checked
                  ).allImgHeight
                }
                showOption.list.find(option => option.checked).changeHeight =
                  showOption.list.find(option => option.checked).allImgHeight /
                  showOption.list.find(option => option.checked).oldAllImgHeight
                if (!showOption.list.find(option => option.checked).oldScroll) {
                  showOption.list.find(option => option.checked).oldScroll = showImg.scrollTop
                }
                let changeScrollTop = 0
                if (this.$refs.showImgContent && this.$refs.showImgContent.isChangePage) {
                  changeScrollTop =
                    showOption.list.find(option => option.checked).scrollTop /
                    (showOption.list.find(option => option.checked).num || 1)
                }
                //顺序不能动，等比例缩放计算滚动条位置
                showOption.list.find(option => option.checked).num = item.imgWidth / item.width - 0
                showOption.list.find(option => option.checked).canvasObj.stage = null
                if (this.$refs.showImgContent && this.$refs.showImgContent.isChangePage) {
                  document.getElementById('show-img-bottom-' + showOptionIndex).scrollTop =
                    (changeScrollTop * showOption.list.find(option => option.checked).num).toFixed(0) - 0
                  if (showOption.list.find(option => option.checked)) {
                    showOption.list.find(option => option.checked).scrollTop =
                      changeScrollTop * showOption.list.find(option => option.checked).num
                    if (showOption.list.find(option => option.checked).num == 1) {
                      showOption.list.find(option => option.checked).oldScroll =
                        changeScrollTop * showOption.list.find(option => option.checked).num
                      if (
                        showOption.list.find(option => option.checked).oldScroll >
                        showOption.list.find(option => option.checked).allImgHeight -
                          document.getElementById('show-img-bottom-' + showOptionIndex).getClientRects()[0].height -
                          9
                      ) {
                        showOption.list.find(option => option.checked).oldScroll =
                          showOption.list.find(option => option.checked).allImgHeight -
                          document.getElementById('show-img-bottom-' + showOptionIndex).getClientRects()[0].height -
                          9
                      }
                    }
                    if (
                      showOption.list.find(option => option.checked).allImgHeight &&
                      showOption.list.find(option => option.checked).scrollTop >
                        showOption.list.find(option => option.checked).allImgHeight -
                          document.getElementById('show-img-bottom-' + showOptionIndex).getClientRects()[0].height -
                          9
                    ) {
                      if (
                        showOption.list.find(option => option.checked).allImgHeight -
                          document.getElementById('show-img-bottom-' + showOptionIndex).getClientRects()[0].height -
                          9 >
                        0
                      ) {
                        showOption.list.find(option => option.checked).scrollTop =
                          showOption.list.find(option => option.checked).allImgHeight -
                          document.getElementById('show-img-bottom-' + showOptionIndex).getClientRects()[0].height -
                          9
                      } else {
                        showOption.list.find(option => option.checked).scrollTop = 0
                      }
                    }
                  }
                }
              }
            })
        }
      })
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.maxImgHeight = heightList.sort((a, b) => b - a)[0]
        if (this.$refs.showImgContent.maxImgHeight) {
          this.changeNum = (this.$refs.showImgContent.maxImgHeight / 2190).toFixed(0) - 0
        }
      }
      this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
      this.bottomHeight = document.getElementsByClassName('show-img-content')[0].clientHeight
      if (this.skipItem && !this.isSkip) {
        this.isSkip = true
        if (document.getElementsByClassName('show-img-bottom')[1])
          document.getElementsByClassName('show-img-bottom')[1].scrollTop = this.skipItem.imgTop - 153
        document.getElementsByClassName('show-img-bottom')[0].scrollTop = this.skipItem.imgTop - 153
        if (this.showOptions[0] && this.showOptions[0].list) {
          this.showOptions[0].list.find(option => option.checked).scrollTop = this.skipItem.imgTop - 153
        }
        if (this.showOptions[1] && this.showOptions[1].list) {
          this.showOptions[1].list.find(option => option.checked).scrollTop = this.skipItem.imgTop - 153
        }
        this.changeSelectIdx(selectIdx)
      }
      if (this.$refs.showImgContent) {
        /* this.$refs.showImgContent.draggableShow = false */
        this.$nextTick(() => {
          this.$refs.showImgContent.draggableShow = true
        })
        this.$refs.showImgContent.isShow = false
        let timeOut = setTimeout(() => {
          this.$refs.showImgContent.isShow = true
          clearTimeout(timeOut)
        }, 0)
      }
      let timeFormat = setTimeout(() => {
        if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = false
        clearTimeout(timeFormat)
      }, 0)
    },
    createdCanvas() {
      this.$nextTick(() => {
        this.showOptions.forEach((showOption, showOptionIndex) => {
          showOption.list.forEach((option, optionIndex) => {
            if (!option.checked) return
            if (option.title == '原稿' || option.title == '原稿JPG' || option.title == '实时') return
            /*           option.canvasObj.canvas = document.getElementById('canvas-' + showOptionIndex + '-' + optionIndex)
          option.canvasObj.canvas.setAttribute(
            'width',
            document.getElementById('canvas-' + showOptionIndex + '-' + optionIndex).parentNode.offsetWidth
          )
          option.canvasObj.canvas.setAttribute(
            'height',
            document.getElementById('canvas-' + showOptionIndex + '-' + optionIndex).parentNode.offsetHeight
          )
          option.canvasObj.ctx = option.canvasObj.canvas.getContext('2d', { willReadFrequently: true })
          option.canvasObj.drawdown = false
          option.canvasObj.startPointX = 0
          option.canvasObj.startPointY = 0
          option.canvasObj.ctx.imageSmoothingEnabled = true
          option.canvasObj.ctx.imageSmoothingQuality = 'high' / option.canvasObj.ctx.translate(0.5, 0.5)
          option.canvasObj.ctx.lineWidth = this.$refs.showImgContent.sliderValue
          option.canvasObj.toolcontrol = { pencil: false, line: false }
          option.canvasObj.convasData = {}
          option.canvasObj.currentPath = {}
          option.canvasObj.undoList = []
          option.canvasObj.redoList = []
          option.canvasObj.penList = []
          option.canvasObj.drawcolor = this.$refs.showImgContent.platteColor
          option.canvasObj.curtool = ''
          option.canvasObj.textvalue = ''
          option.canvasObj.dragIndex = -1
          option.canvasObj.dragStartX = 0
          option.canvasObj.dragStartY = 0
          option.canvasObj.isDrag = false */
            if (
              this.$refs.showImgContent.$refs['canvas-' + showOptionIndex + '-' + optionIndex] &&
              this.$refs.showImgContent.$refs['canvas-' + showOptionIndex + '-' + optionIndex][0]
            ) {
              option.canvasObj.stage = this.$refs.showImgContent.$refs[
                'canvas-' + showOptionIndex + '-' + optionIndex
              ][0]
              option.canvasObj.stageSize = {
                width: option.canvasObj.stage.$el.parentNode.offsetWidth,
                height: option.canvasObj.stage.$el.parentNode.offsetHeight,
                pixelRatio: 4
              }
            } else {
              option.canvasObj.stage = null
            }
            this.$refs.showImgContent.lines = []
            this.$refs.showImgContent.rects = []
            this.$refs.showImgContent.arrows = []
            this.$refs.showImgContent.rings = []
            this.$refs.showImgContent.texts = []
            this.$nextTick(() => {
              if (option.canvasObj.stage) {
                option.canvasObj.url = option.canvasObj.stage.getStage().toDataURL()
              }
            })
          })
        })
      })
    },
    getFontFamily(departId) {
      getAction('/fonts/list', { current: '1', size: '-1', pageSize: '1000', pageNo: '1', fieId: 'fontFamily' }).then(
        res => {
          if (res.success) {
            let fontFamilyList = res.data.records.filter(item => item.departments.some(d => d.departId == departId))
            fontFamilyList.forEach(item => {
              item.fontFamily = 'fontCssView' + item.fontFamily
            })
            batchStyleInsertHeader(fontFamilyList || res.data)
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          }
        }
      )
    },
    allMousemove(e) {
      if (this.dragContent.contentDrag) {
        if (!this.dragContent.isFixed) {
          if (this.offsetX == -1) this.offsetX = e.offsetX
          const dom = document.getElementsByClassName('translate-bottom-right')[0]
          if (e.target.getClientRects()[0].x + 400 >= dom.getClientRects()[0].x) {
            this.dragContent.isOpacity = true
          } else {
            this.dragContent.isOpacity = false
          }
          this.dragContent.mouseX = e.clientX - this.offsetX + 20
          this.dragContent.mouseY = e.clientY - this.topHeight
        } else {
          if (this.offsetX == -1) this.offsetX = e.offsetX
          this.dragContent.mouseX = e.clientX - this.offsetX + 20
          this.dragContent.mouseY = e.clientY - this.topHeight
          let item = null
          this.contentList.forEach(i => {
            if (i.isFixed && i.id != this.dragContent.id) {
              item = i
            }
          })
          if (item && document.getElementById('content-list-' + item.id)) {
            let targetDom = document.getElementById('content-list-' + item.id)
            if (!targetDom) return
            let position = targetDom.getClientRects()[0]
            if (
              e.clientX > position.x &&
              e.clientX < position.x + position.width &&
              e.clientY > position.y &&
              e.clientY < position.y + position.height
            ) {
              item.isSort = true
              this.dragContent.isSort = true
            } else {
              item.isSort = false
              this.dragContent.isSort = false
            }
          }
          if (!this.dragContent.isSort) {
            let parentNode = document.getElementsByClassName('fixed-sidebar')[0]
            let position = parentNode.getClientRects()[0]
            if (
              e.clientX < position.x ||
              e.clientX > position.x + position.width ||
              e.clientY < position.y ||
              e.clientY > position.y + position.height
            ) {
              this.dragContent.isOut = true
            } else {
              this.dragContent.isOut = false
            }
          }
        }
      }
      //拖拽画板
      if (this.$refs.showImgContent && this.$refs.showImgContent.platteDrag) {
        let moveDom = document.getElementsByClassName('platte')[0]
        let changeLeft = 135
        if (this.taskDetail.departName.indexOf('原创部') > -1) {
          changeLeft = 112
        }
        moveDom.style.top = e.clientY - changeLeft - 35 + 'px'
        moveDom.style.left =
          moveDom.style.left.split('px')[0] - 0 + (e.clientX - this.$refs.showImgContent.clientX) + 'px'
        this.$refs.showImgContent.clientX = e.clientX
      }
      //修改图片显示区域尺寸
      if (this.$refs.showImgContent && this.$refs.showImgContent.isChangeImgwidth) {
        this.$refs.showImgContent.isChangePage = true
        let changeDom = document.getElementsByClassName('show-img')[0]
        if (changeDom.style.width == '100%' && e.movementX > 0) {
          changeDom.style.width = '100%'
          this.$refs.showImgContent.isChangePage = false
        } else {
          if (changeDom.style.width && changeDom.style.width.indexOf('calc') > -1) {
            let wdith =
              changeDom.style.width
                .split('% ')[1]
                .replaceAll(' ', '')
                .split('px')[0] - 0
            let num = window.innerWidth / 1920
            if (wdith < -900 * num && e.movementX < 0) {
              this.$refs.showImgContent.isChangePage = false
              return
            }
            changeDom.style.width = `calc(100% + ${wdith + e.movementX}px)`
            if (wdith > 0) {
              changeDom.style.width = '100%'
            }
          } else {
            changeDom.style.width = `calc(100% + ${e.movementX}px)`
          }
        }
        this.$refs.showImgContent.isShow = false
        let timeOut = setTimeout(() => {
          this.$refs.showImgContent.isShow = true
          clearTimeout(timeOut)
        }, 0)
        /* this.reScrollTop() */
        this.$nextTick(() => {
          this.getShowImgListPostions()
        })
      }
      if (
        this.$refs.showImgContent &&
        this.$refs.showImgContent.isChangeItemWidth &&
        this.$refs.showImgContent.changeWidthShowOptionIndex > -1
      ) {
        this.$refs.showImgContent.isChangePage = true
        this.showOptions.forEach((s, idx) => {
          if (idx == this.$refs.showImgContent.changeWidthShowOptionIndex) {
            if (!s.changeWidth) s.changeWidth = 0
            s.changeWidth = s.changeWidth - e.movementX
          } else if (idx == this.$refs.showImgContent.changeWidthShowOptionIndex + 1) {
            if (!s.changeWidth) s.changeWidth = 0
            s.changeWidth = s.changeWidth + e.movementX
          }
        })
        this.$refs.showImgContent.isShow = false
        let timeOut = setTimeout(() => {
          this.$refs.showImgContent.isShow = true
          clearTimeout(timeOut)
        }, 0)
        this.$nextTick(() => {
          this.getShowImgListPostions()
          this.$refs.centerButtonList.changeSyncWidth()
        })
      }
    },
    //缩放时计算滚动条位置
    reScrollTop: simpleDebounce(async function() {
      this.reScrollTopSim()
    }, 100),
    reScrollTopSim() {
      this.showOptions.forEach((showOption, showOptionIndex) => {
        let showImg = document.getElementById('show-img-bottom-' + showOptionIndex)
        if (showOption.list.find(option => option.checked).changeHeight) {
          showImg.scrollTop =
            showOption.list.find(option => option.checked).oldScroll *
            showOption.list.find(option => option.checked).changeHeight
        }
      })
    },
    allMouseup(e) {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.platteDrag = false
        this.$refs.showImgContent.isChangeImgwidth = false
        this.$refs.showImgContent.isChangeItemWidth = false
        this.$refs.showImgContent.changeWidthShowOptionIndex = -1
      }
      if (this.dragContent.contentDrag) {
        if (!this.dragContent.isSort) {
          if (this.dragContent.isOut && this.dragContent.isFixed) {
            let changeDom = document.getElementsByClassName('show-img')[0]
            changeDom.style.width = '100%'
            this.$refs.showImgContent.isChangePage = false
            if (this.offsetX == -1) this.offsetX = e.offsetX
            this.dragContent.mouseX = e.clientX - this.offsetX + 20
            this.dragContent.mouseY = e.clientY - this.topHeight
            this.dragContent.contentDrag = false
            this.dragContent.isFixed = false
            this.$nextTick(() => {
              if (this.$refs.optionList) {
                this.optionListWidth = this.$refs.optionList.clientWidth
              }
              if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
              this.getShowImgListPostions()
              this.getPageListPostions()
            })
            this.dragContent.isOpacity = false
            this.dragContent.isOut = false
          } else if (!this.dragContent.isOut && this.dragContent.isFixed) {
            let changeDom = document.getElementsByClassName('show-img')[0]
            changeDom.style.width = '100%'
            this.$refs.showImgContent.isChangePage = false
            if (this.offsetX == -1) this.offsetX = e.offsetX
            this.dragContent.mouseX = e.clientX - this.offsetX + 20
            this.dragContent.mouseY = e.clientY - this.topHeight
            this.dragContent.contentDrag = false
            this.dragContent.isFixed = true
            this.$nextTick(() => {
              const dom = document.getElementById('content-list-' + this.dragContent.id)
              dom.style.position = 'relative'
              dom.style.left = '0'
              dom.style.top = '0'
              dom.style.zIndex = '1'
              dom.style.boxShadow = 'none'
              dom.style.borderRadius = '0'
              document.getElementsByClassName('fixed-sidebar')[0].appendChild(dom)
              this.dragContent.order = document.getElementsByClassName('fixed-sidebar')[0].childNodes.length
              this.contentList.forEach(item => {
                if (item.id == this.dragContent.id && item.order == this.dragContent.order) {
                  item.order--
                }
              })
              if (this.$refs.optionList) {
                this.optionListWidth = this.$refs.optionList.clientWidth
              }
              if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
              this.getShowImgListPostions()
              this.getPageListPostions()
            })
            this.dragContent.isOpacity = false
          } else {
            if (this.offsetX == -1) this.offsetX = e.offsetX
            this.dragContent.mouseX = e.clientX - this.offsetX + 20
            this.dragContent.mouseY = e.clientY - this.topHeight
            this.dragContent.contentDrag = false
            if (this.dragContent.isOpacity) {
              let changeDom = document.getElementsByClassName('show-img')[0]
              changeDom.style.width = '100%'
              this.$refs.showImgContent.isChangePage = false
              this.dragContent.isFixed = true
              this.$nextTick(() => {
                const dom = document.getElementById('content-list-' + this.dragContent.id)
                dom.style.position = 'relative'
                dom.style.left = '0'
                dom.style.top = '0'
                dom.style.zIndex = '1'
                dom.style.boxShadow = 'none'
                dom.style.borderRadius = '0'
                document.getElementsByClassName('fixed-sidebar')[0].appendChild(dom)
                this.dragContent.order = document.getElementsByClassName('fixed-sidebar')[0].childNodes.length
                this.contentList.forEach(item => {
                  if (item.id == this.dragContent.id && item.order == this.dragContent.order) {
                    item.order--
                  }
                })
                if (this.$refs.optionList) {
                  this.optionListWidth = this.$refs.optionList.clientWidth
                }
                if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
                this.getShowImgListPostions()
                this.getPageListPostions()
              })
            }
            this.dragContent.isOpacity = false
          }
        } else {
          if (this.offsetX == -1) this.offsetX = e.offsetX
          this.dragContent.mouseX = e.clientX - this.offsetX + 20
          this.dragContent.mouseY = e.clientY - this.topHeight
          this.dragContent.contentDrag = false
          this.dragContent.isOpacity = false
          this.dragContent.isFixed = true
          this.dragContent.isOut = false
          this.contentList.forEach(item => {
            if (item.isSort && item.id != this.dragContent.id) {
              let order = this.dragContent.order
              if (order) {
                this.dragContent.order = 0
                item.order = 1
              } else {
                this.dragContent.order = 1
                item.order = 0
              }
              this.dragContent.isSort = false
              item.isSort = false
            }
          })
          this.$nextTick(() => {
            const dom = document.getElementById('content-list-' + this.dragContent.id)
            dom.style.position = 'relative'
            dom.style.left = '0'
            dom.style.top = '0'
            dom.style.zIndex = '1'
            dom.style.boxShadow = 'none'
            dom.style.borderRadius = '0'
            document.getElementsByClassName('fixed-sidebar')[0].appendChild(dom)
            if (this.$refs.optionList) {
              this.optionListWidth = this.$refs.optionList.clientWidth
            }
            if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
            this.getShowImgListPostions()
            this.getPageListPostions()
          })
        }
      }
      this.offsetX = -1
    },
    async openPlatteModal() {
      const res = await getAction('user/annotation/style')
      if (res.code == 200) {
        if (res.data && this.$refs.showImgContent) {
          this.$refs.showImgContent.platteTypStyle = JSON.parse(res.data.style)
        }
      } else {
        this.$message.error(res.msg)
      }
      this.selectedPlatte = !this.selectedPlatte
      if (this.selectedPlatte) {
        this.isAddAnnotation = false
        this.selectedDiff = false
        let dom = document.getElementById('platte-option')
        Array.from(document.getElementsByClassName('show-img-content-item')).forEach((d, dIdx) => {
          if (
            this.showOptions[dIdx].list.find(
              option => option.checked && option.isLatest && option.title != '实时' && option.title != '原稿'
            )
          ) {
            d.style.zIndex = 1054
          }
        })
        this.$nextTick(() => {
          let moveDom = document.getElementsByClassName('platte')[0]
          this.stageSize = {
            width: document.getElementsByClassName('canvas-content')[0].offsetWidth,
            height: document.getElementsByClassName('canvas-content')[0].offsetHeight,
            pixelRatio: 4
          }
          moveDom.style.left = dom.getClientRects()[0].x - 155 + 'px'
          this.$refs.showImgContent.openPlatte()
        })
      } else {
        Array.from(document.getElementsByClassName('show-img-content-item')).forEach(d => {
          d.style.zIndex = 0
        })
      }
    },
    closePlatte() {
      this.selectedPlatte = false
      Array.from(document.getElementsByClassName('show-img-content-item')).forEach(d => {
        d.style.zIndex = 0
      })
    },
    moveOption(data) {
      let value = data.value
      let changeOption = null
      let changeIdx = -1
      let deleOptionIdx = -1
      this.showOptions.forEach((showOption, showOptionIdx) => {
        changeIdx = showOption.list.findIndex(option => option.value == value)
        if (changeIdx > -1) {
          changeOption = JSON.parse(JSON.stringify(showOption.list.find(option => option.value == value)))
          showOption.list.splice(changeIdx, 1)
          if (showOption.list.length && showOption.list.every(option => !option.checked)) {
            showOption.list[showOption.list.length - 1].checked = true
          }
          if (!showOption.list.length) {
            deleOptionIdx = showOptionIdx
          }
        }
      })
      if (changeOption) {
        this.showOptions[data.showOptionIndex].list.forEach(option => {
          option.checked = false
        })
        changeOption.checked = true
        this.showOptions[data.showOptionIndex].list.push(changeOption)
      }
      if (deleOptionIdx > -1 && data.showOptionIndex != deleOptionIdx) this.showOptions.splice(deleOptionIdx, 1)
      if (data.exchangeValue) {
        let list = this.showOptions[data.showOptionIndex].list
        let exIndex = list.findIndex(option => option.value == data.exchangeValue)

        if (exIndex > -1 && exIndex + 1 != list.length - 1) {
          ;[list[exIndex], list[list.length - 1]] = [list[list.length - 1], list[exIndex]]
        }
      }
      let sliderValues = []
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          option.manuscriptList?.forEach(file => {
            file.fileName = file.fileName.replaceAll('<br>', '\n')
            if (option.checked) {
              file.annotations?.forEach(local => {
                sliderValues.push(local.position)
              })
            }
          })
          option.canvasObj = {
            stageSize: { width: 0, height: 0 },
            stage: null,
            url: ''
          }
        })
      })
      if (sliderValues.length) {
        sliderValues = sliderValues.sort((a, b) => b.width - a.width)
        this.$refs.centerButtonList.sliderValue = sliderValues[0].sliderValue || 100
        this.$refs.centerButtonList.sliderHandledChange(this.$refs.centerButtonList.sliderValue)
      }
      this.saveIndex++
      if (this.changeProcess[this.saveIndex]) {
        this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
      } else {
        this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
      }
      this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
      let timeOut = setTimeout(() => {
        this.getShowImgListPostions()
        this.getPageListPostions()
        clearTimeout(timeOut)
      }, 500)
      this.saveScrollTop()
      if (this.$refs.pageListContent) {
        if (!this.allOption.find(option => option.title == this.$refs.pageListContent.optionTitle).checked) {
          let arr = this.allOption.filter(option => option.checked).sort((a, b) => a.index - b.index)
          if (arr.length) {
            let option = null
            arr.forEach(o => {
              if (o.checked) {
                option = o
              }
            })
            if (option) {
              this.$refs.pageListContent.optionTitle = option.title
              this.pageOptionChange(option.title)
            }
          }
        }
      }
      if (this.$refs.centerButtonList) {
        this.$refs.centerButtonList.changeCheckSyncTabs()
      }
      this.$refs.showImgContent.isShow = false
      let timeOut2 = setTimeout(() => {
        this.$refs.showImgContent.isShow = true
        clearTimeout(timeOut2)
      }, 0)
    },
    pushOption(value) {
      let changeOption = null
      let changeIdx = -1
      let deleOptionIdx = -1
      this.showOptions.forEach((showOption, showOptionIdx) => {
        changeIdx = showOption.list.findIndex(option => option.value == value)
        if (changeIdx > -1) {
          changeOption = JSON.parse(JSON.stringify(showOption.list.find(option => option.value == value)))
          showOption.list.splice(changeIdx, 1)
          if (showOption.list.length && showOption.list.every(option => !option.checked)) {
            showOption.list[showOption.list.length - 1].checked = true
          }
          if (!showOption.list.length) {
            deleOptionIdx = showOptionIdx
          }
        }
      })
      if (deleOptionIdx > -1) this.showOptions.splice(deleOptionIdx, 1)
      if (changeOption) {
        changeOption.checked = true
        this.showOptions.push({ list: [changeOption] })
      }
      let sliderValues = []
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          option.manuscriptList?.forEach(file => {
            file.fileName = file.fileName.replaceAll('<br>', '\n')
            if (option.checked) {
              file.annotations?.forEach(local => {
                sliderValues.push(local.position)
              })
            }
          })
          option.canvasObj = {
            stageSize: { width: 0, height: 0 },
            stage: null,
            url: ''
          }
        })
      })
      if (sliderValues.length) {
        sliderValues = sliderValues.sort((a, b) => b.width - a.width)
        this.$refs.centerButtonList.sliderValue = sliderValues[0].sliderValue || 100
        this.$refs.centerButtonList.sliderHandledChange(this.$refs.centerButtonList.sliderValue)
      }
      this.saveIndex++
      if (this.changeProcess[this.saveIndex]) {
        this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
      } else {
        this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
      }
      let timeOut = setTimeout(() => {
        this.getShowImgListPostions()
        this.getPageListPostions()
        clearTimeout(timeOut)
      }, 500)
      this.saveScrollTop()
      if (this.$refs.pageListContent) {
        if (!this.allOption.find(option => option.title == this.$refs.pageListContent.optionTitle).checked) {
          let arr = this.allOption.filter(option => option.checked).sort((a, b) => a.index - b.index)
          if (arr.length) {
            let option = null
            arr.forEach(o => {
              if (o.checked) {
                option = o
              }
            })
            if (option) {
              this.$refs.pageListContent.optionTitle = option.title
              this.pageOptionChange(option.title)
            }
          }
        }
      }
      if (this.$refs.centerButtonList) {
        this.$refs.centerButtonList.changeCheckSyncTabs()
      }
      this.$refs.showImgContent.isShow = false
      let timeOut2 = setTimeout(() => {
        this.$refs.showImgContent.isShow = true
        clearTimeout(timeOut2)
      }, 0)
    },
    changeOption(value) {
      let showOptionIndex = -1
      let scrollTop = 0
      this.showOptions.forEach((showOption, index) => {
        if (showOption.list.some(option => option.value == value)) {
          showOption.list.forEach(option => {
            if (option.value == value) {
              option.checked = true
              showOptionIndex = index
              scrollTop = option.scrollTop
            } else {
              option.checked = false
            }
          })
        }
      })
      let sliderValues = []
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          option.manuscriptList?.forEach(file => {
            file.fileName = file.fileName.replaceAll('<br>', '\n')
            if (option.checked) {
              file.annotations?.forEach(local => {
                sliderValues.push(local.position)
              })
            }
          })
          option.canvasObj = {
            stageSize: { width: 0, height: 0 },
            stage: null,
            url: ''
          }
        })
      })
      if (sliderValues.length) {
        sliderValues = sliderValues.sort((a, b) => b.width - a.width)
        this.$refs.centerButtonList.sliderValue = sliderValues[0].sliderValue || 100
        this.$refs.centerButtonList.sliderHandledChange(this.$refs.centerButtonList.sliderValue)
      }
      this.saveIndex++
      if (this.changeProcess[this.saveIndex]) {
        this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.showOptions))
      } else {
        this.changeProcess.push(JSON.parse(JSON.stringify(this.showOptions)))
      }
      this.loadImg()
      this.saveScrollTop()
      if (showOptionIndex > -1) {
        let timeOut = setTimeout(() => {
          let dom = document.getElementById('show-img-bottom-' + showOptionIndex)
          dom.scrollTop = scrollTop
          this.getShowImgListPostions()
          this.getPageListPostions()
          clearTimeout(timeOut)
        }, 500)
      }
      if (this.$refs.pageListContent) {
        if (!this.allOption.find(option => option.title == this.$refs.pageListContent.optionTitle).checked) {
          let arr = []
          this.showOptions.forEach(showOption => {
            showOption.list?.forEach(option => {
              arr.push(option)
            })
          })
          if (arr.length) {
            let option = null
            arr.forEach(o => {
              if (o.checked) {
                option = o
              }
            })
            if (option) {
              this.$refs.pageListContent.optionTitle = option.title
              this.pageOptionChange(option.title)
            }
          }
        }
      }
      if (this.$refs.centerButtonList) {
        this.$refs.centerButtonList.changeCheckSyncTabs()
      }
      this.$refs.showImgContent.isShow = false
      let timeOut = setTimeout(() => {
        this.$refs.showImgContent.isShow = true
        clearTimeout(timeOut)
      }, 0)
    },
    optionClick(e, item) {
      item.mouseY = e.target.getClientRects()[0].y - e.target.getClientRects()[0].height
      if (item.visible && item.isFixed) {
        const dom = document.getElementById('content-list-' + item.id)
        if (!dom) {
          item.visible = false
          return
        }
        item.mouseX = dom.getClientRects()[0].x + 20
        item.mouseY = dom.getClientRects()[0].y - 36
        dom.style.position = 'absolute'
        dom.style.boxShadow = '2px 1px 6px 2px rgba(0, 0, 0, 0.2)'
        dom.style.borderRadius = '5px'
        document.getElementsByClassName('content-list-view')[0].appendChild(dom)
        this.$nextTick(() => {
          if (this.$refs.optionList) {
            this.optionListWidth = this.$refs.optionList.clientWidth
          }
          if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
          this.getShowImgListPostions()
          this.getPageListPostions()
        })
      }
      item.visible = !item.visible
      if (this.$refs.contentList) {
        this.$refs.contentList.noDrag = false
      }
      if (item.visible && item.isFixed) {
        this.$nextTick(() => {
          let changeDom = document.getElementsByClassName('show-img')[0]
          changeDom.style.width = '100%'
          const dom = document.getElementById('content-list-' + item.id)
          if (!dom) {
            item.visible = false
            return
          }
          dom.style.position = 'relative'
          dom.style.left = '0'
          dom.style.top = '0'
          dom.style.zIndex = '1'
          dom.style.boxShadow = 'none'
          dom.style.borderRadius = '0'
          document.getElementsByClassName('fixed-sidebar')[0].appendChild(dom)
          if (this.$refs.optionList) {
            this.optionListWidth = this.$refs.optionList.clientWidth
          }
          if (this.$refs.showImgContent) this.$refs.showImgContent.isChangePage = true
          this.getShowImgListPostions()
          this.getPageListPostions()
        })
      }

      switch (item.id) {
        case 2:
          if (this.$refs.contentList) {
            this.$refs.contentList.getLocalList()
          }
          break
        case 3:
          this.getTextList(2)
          break
      }
    },
    async getTextList(type, keyword) {
      let params = {
        type: type || null,
        keyword: keyword || null,
        chapterId: this.taskDetail.chapterId
      }
      const res = await getAction('/original/text/list', params)
      if (res.code == 200) {
        this.textList = res.data
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    getAllData() {
      this.allLoadding = true
      this.getTask()
      this.getPermsission()
    },
    loadImg() {
      this.$nextTick(() => {
        if (this.$refs.pageListContent) {
          this.$refs.pageListContent.loadImg()
        }
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.loadImg()
        }
      })
    },
    async getChapterList() {
      const res = await getAction(
        `/original/skip/task?productionId=${this.taskDetail.productionId}&stageId=${this.taskDetail.stageId}`
      )
      if (res.data) {
        this.chapterList = res.data
        this.chapterList.forEach(chapter => {
          chapter.label =
            this.taskDetail.productSequenceNo + '-' + chapter.chapterOrder + '-' + this.taskDetail.stageName
        })
        if (this.taskDetail.taskStatus == '可开始' || this.taskDetail.taskStatus == '驳回') {
          if (this.taskDetail.taskStatus == '驳回' && this.taskDetail.rejectTaskId) {
            this.changeAll = true
          } else {
            this.changeAll = false
          }
          if (this.taskDetail.taskStatus != '驳回') {
            this.changeAll = true
          }
        } else {
          this.changeAll = false
        }
        if (this.chapterList.every(chapter => chapter.chapterId != this.taskDetail.chapterId)) {
          this.changeAll = false
        } else {
          let chapter = this.chapterList.find(chapter => chapter.chapterId == this.taskDetail.chapterId)
          if (
            chapter.ownerId != this.userInfo.id &&
            this.userInfo.roles.every(
              item => item.roleCode != 'boss' && item.roleCode != 'admin' && item.roleCode != 'tongchou'
            )
          ) {
            this.changeAll = false
          }
        }
        if (this.userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin')) {
          this.isAdmin = true
        }
        if (this.$refs.pageListContent) {
          this.$refs.pageListContent.chapterId = this.taskDetail.chapterId
        }
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    async getPageList() {
      let params = {
        chapterId: this.$route.params.chapterId,
        id: this.$route.params.imgId,
        currentStage: this.taskDetail.stageName,
        loadLock: this.loadLock
      }
      this.skipImgId = this.$route.params.imgId
      const res = await getAction('/finish/manuscript/main', params)
      this.allLoadding = false
      if (res.code == 200) {
        if (this.rollPositionArray && this.rollPositionArray.length) {
          this.showOptions = []
          this.rollPositionArray.forEach((rl, rlIndex) => {
            this.showOptions.push({ list: [] })
          })
          this.rollPositionArray.forEach((rl, rlIndex) => {
            rl.list.forEach((r, rIndex) => {
              this.showOptions[rlIndex].list.push({
                title: r.title,
                value: rlIndex + '-' + rIndex,
                checked: r.checked || false,
                scrollTop: r.scrollTop,
                allImgHeight: 0,
                changeWidth: 0,
                localChecked: false,
                canvasObj: {
                  canvas: null,
                  ctx: null,
                  url: ''
                },
                manuscriptList: []
              })
            })
            if (this.showOptions[rlIndex].list.every(option => !option.checked)) {
              this.showOptions[rlIndex].list[this.showOptions[rlIndex].list.length - 1].checked = true
            }
          })
        }
        res.data.subfieldList.forEach(item => {
          item.manuscriptList?.forEach(m => {
            m.annotations?.forEach(a => {
              a.size = 30
            })
          })
          this.showOptions.forEach(showOption => {
            showOption.list.forEach(option => {
              if (option.title == item.title) {
                if (item.title == '原稿') {
                  item.manuscriptList.forEach(p => {
                    p.id = p.id + 'orangin'
                  })
                }
                option.manuscriptList = item.manuscriptList
                option.isLatest = item.isLatest
                option.index = item.index
              }
            })
          })
          if (this.showOptions.every(showOption => showOption.list.every(option => option.title != item.title))) {
            this.showOptions[this.showOptions.length - 1].list.push({
              title: item.title,
              value: '',
              checked: true,
              scrollTop: 0,
              allImgHeight: 0,
              changeWidth: 0,
              localChecked: false,
              canvasObj: {
                stageSize: { width: 0, height: 0 },
                stage: null,
                url: ''
              },
              manuscriptList: item.manuscriptList || []
            })
          }
        })
        this.showOptions.forEach(showOption => {
          let deleArr = []
          showOption.list.forEach((option, optionIndex) => {
            if (!option.manuscriptList.length) {
              deleArr.push(optionIndex)
            }
          })
          let changeChecked = false
          deleArr.forEach((d, dIdx) => {
            if (showOption.list[d - dIdx].checked) changeChecked = true
            showOption.list.splice(d - dIdx, 1)
          })
          if (changeChecked && showOption.list[showOption.list.length - 1]) {
            showOption.list[showOption.list.length - 1].checked = true
          }
        })
        this.allOption = []
        let titles = []
        let sliderValues = []
        this.showOptions.forEach((showOption, showOptionIndex) => {
          showOption.list.forEach((option, optionIndex) => {
            option.value = showOptionIndex + '-' + optionIndex
            this.allOption.push(option)
            /* if (showOption.list[showOption.list.length - 1].checked && optionIndex != showOption.list.length - 1) {
              option.checked = false
            } */
            if (showOptionIndex == this.showOptions.length - 1 && optionIndex == showOption.list.length - 1) {
              option.localChecked = true
            }
            option.manuscriptList?.forEach(file => {
              file.fileName = file.fileName.replaceAll('<br>', '\n')
              file.annotations?.forEach(local => {
                if (option.checked) {
                  sliderValues.push(local.position)
                }
                if (local.targetStage) {
                  this.allStages.push(local.targetStage)
                }
                if (!local.injuryReason) local.injuryReason = undefined
              })
              if (!file.annotations) file.annotations = []
            })
            if (option.checked && option.title != '原稿' && option.title != '实时' && option.title != '原稿JPG')
              titles.push(option.title)
            if (option.title == '实时') {
              option.manuscriptList?.forEach(page => {
                page.textAreas?.forEach(item => {
                  item.showTexts = []
                  let arr = []
                  let brIds = []
                  item.brIndexs = []
                  item.pIndexs = []
                  item.characterSentences.forEach((t, pushTtextIndex) => {
                    if (
                      item.characterSentences[pushTtextIndex + 1] &&
                      item.characterSentences[pushTtextIndex + 1].phonetic &&
                      item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                    ) {
                      item.pIndexs.push(pushTtextIndex)
                    }
                    if (t.characterText != '<br>') {
                      arr.push(t)
                    } else {
                      brIds.push(t.characterSentenceId)
                      item.brIndexs.push(pushTtextIndex)
                      item.showTexts.push({ pId: '', texts: arr, pushTtextIndex })
                      arr = []
                    }
                    if (pushTtextIndex == item.characterSentences.length - 1 && arr.length) {
                      item.showTexts.push({ pId: '', texts: arr, pushTtextIndex })
                    }
                  })
                  brIds.forEach((brId, brPushIdx) => {
                    if (item.showTexts[brPushIdx + 1]) {
                      item.showTexts[brPushIdx + 1].pId = brId
                    }
                  })
                  item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                })
              })
            }
          })
        })
        if (this.showOptions.length == 2 && !this.showOptions[0].list.length) {
          this.showOptions.splice(0, 1)
        }
        if (sliderValues.length) {
          sliderValues = sliderValues.sort((a, b) => b.width - a.width)
          this.$refs.centerButtonList.sliderValue = sliderValues[0].sliderValue || 100
          this.$refs.centerButtonList.sliderHandledChange(this.$refs.centerButtonList.sliderValue)
        }
        this.allOption = this.allOption.sort((a, b) => a.index - b.index)
        this.selectedTitle = this.allOption[0].title
        if (this.$refs.contentList) {
          this.$refs.contentList.selectedTitle = this.allOption[0].title
        }
        this.allStages = [...new Set(this.allStages)]
        /*         if (this.$refs.contentList) {
          this.$refs.contentList.checkedStages = this.allStages
        } */
        if (this.$refs.pageListContent) {
          let title = '初稿'
          this.allOption.forEach(option => {
            if (option.checked) {
              title = option.title
            }
          })
          this.$refs.pageListContent.optionTitle = title
        }
        this.changeProcess = [JSON.parse(JSON.stringify(this.showOptions))]
        this.oldShowOptions = JSON.parse(JSON.stringify(this.showOptions))
        this.loadImg()
        this.$nextTick(() => {
          if (this.$refs.showImgContent) {
            if (titles.length > 1) {
              this.$refs.showImgContent.diffData.leftTitle = titles[0]
              this.$refs.showImgContent.diffData.rightTitle = titles[1]
            } else if (titles.length == 1) {
              this.$refs.showImgContent.diffData.leftTitle = titles[0]
              this.$refs.showImgContent.diffData.rightTitle = titles[0]
            }
          }
          if (this.$refs.centerButtonList) {
            this.$refs.centerButtonList.changeCheckSyncTabs()
          }
        })
        this.getLocalList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async getLocalList() {
      const res = await postAction('/annotation/list', {
        chapterId: this.taskDetail.chapterId,
        productionId: this.taskDetail.productionId
      })
      if (res.code == 200) {
        this.leftAnnotationList = []
        this.rightAnnotationList = []
        let pageListData = null
        this.showOptions.forEach(showOption => {
          showOption?.list.forEach(option => {
            if (option.title == '原稿' || option.title == '实时') {
              pageListData = option.manuscriptList
            }
          })
        })
        if (!pageListData) return
        this.showAnnotationList = res.data.map(page => {
          return {
            annotationList: page.productAnnotationList,
            idx: pageListData.findIndex(p => p.id == page.pageId),
            pageId: page.pageId
          }
        })
        if (res.data.length) {
          res.data.forEach((page, idx) => {
            this.leftAnnotationList.push({
              annotationList: page.productAnnotationList.filter(item => item.around == 'left'),
              idx,
              pageId: page.pageId
            })
            this.rightAnnotationList.push({
              annotationList: page.productAnnotationList.filter(item => item.around == 'right'),
              idx,
              pageId: page.pageId
            })
          })
          if (this.leftAnnotationList.length != pageListData.length) {
            pageListData.forEach((page, idx) => {
              if (this.leftAnnotationList.findIndex(a => a.pageId == page.id) == -1) {
                this.leftAnnotationList.push({
                  annotationList: [],
                  idx,
                  pageId: page.id
                })
              }
            })
          }
          if (this.rightAnnotationList.length != pageListData.length) {
            pageListData.forEach((page, idx) => {
              if (this.rightAnnotationList.findIndex(a => a.pageId == page.id) == -1) {
                this.rightAnnotationList.push({
                  annotationList: [],
                  idx,
                  pageId: page.id
                })
              }
            })
          }
        }
        this.showAnnotationList.sort((a, b) => a.idx - b.idx)
      }
    },
    async getTask() {
      const res = await getAction('tasks/get/' + this.$route.params.taskId)
      if (res.code == 200) {
        this.taskDetail = res.data
        if (this.taskDetail.systemTypes.every(systemType => systemType != 'IMAGE_TRANSLATION_SYSTEM')) {
          this.contentList = [
            {
              zIndex: 999,
              mouseX: 0,
              mouseY: 100,
              contentDrag: false,
              id: 1,
              title: '对比结果',
              optionTitle: '对比结果',
              optionTitleMini: '对',
              pushpinColor: '#4a4a4a',
              visible: false,
              isPushpin: false,
              allHeight: false,
              showContent: true,
              isOpacity: false,
              isFixed: false,
              isSort: false,
              order: 0,
              isOut: false
            },
            {
              zIndex: 999,
              mouseX: 0,
              mouseY: 100,
              contentDrag: false,
              id: 2,
              title: '批注列表',
              optionTitle: '批注列表',
              optionTitleMini: '批',
              pushpinColor: '#4a4a4a',
              visible: false,
              isPushpin: false,
              allHeight: false,
              showContent: true,
              isOpacity: false,
              isFixed: false,
              isSort: false,
              order: 1,
              isOut: false
            }
          ]
        }
        this.isLocalZhongShen = false
        this.isLocalZhongShen = this.userInfo.positions?.some(
          position => position.code == 'zongshen' && this.taskDetail.isLocal
        )
        this.taskStatus = this.taskDetail.taskStatus
        this.taskConfirmStatus = this.taskDetail.taskConfirmStatus == '已确认' ? true : false
        if (
          (this.taskDetail.toStagesLeaveMessage && this.taskDetail.toStagesLeaveMessage.length) ||
          (this.taskDetail.toStagesAnnotation && this.taskDetail.toStagesAnnotation.length)
        ) {
          this.toStagesAnnotation = this.taskDetail.toStagesAnnotation
          this.toStagesLeaveMessage = this.taskDetail.toStagesLeaveMessage
          this.toStages = [...this.toStagesLeaveMessage]
          this.contentToStages = [...this.toStagesLeaveMessage]
          this.toStagesAnnotation?.forEach(t => {
            if (this.toStages.every(s => s.stageName != t.stageName)) {
              this.toStages.push(t)
            }
          })
          if (this.$refs.showImgContent && this.toStagesAnnotation.length) {
            this.$refs.showImgContent.targetStage = this.toStagesAnnotation[0].stageName
          }
          if (this.$refs.contentList) {
            /* this.taskDetail.checkedStages || [] */
            this.$refs.contentList.checkedStages = this.toStages.map(s => s.stageName) || []
          }
        }

        document.title = this.taskDetail.productionName + ' · ' + this.taskDetail.chapterOrder
        this.getChapterList()
        this.getScrollTop()
        this.getProduction()
        this.getContentList()
      } else {
        this.$message.error(res.msg)
      }
    },
    changeOptionListWidth() {
      if (this.optionListWidth <= 50) {
        this.$refs.optionList.style.width = '5%'
      } else {
        this.$refs.optionList.style.width = '24px'
      }
      this.$nextTick(() => {
        this.showImgWidth = this.$refs.showImg.clientWidth
        this.optionListWidth = this.$refs.optionList.clientWidth
      })
      this.loadImg()
    }
  }
}
</script>

<style scoped lang="less">
.fixed-sidebar {
  width: 400px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  left: -3px;
  box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
  height: max-content !important;
  max-height: 100%;
  overflow-y: auto;
  /* overflow-x: hidden; */
  &::-webkit-scrollbar-button {
    height: 0;
  }
  &::-webkit-scrollbar {
    border-radius: 0;
    position: relative;
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #fff;
  }
  &::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
}
/deep/ .ant-modal-wrap {
  z-index: 9999 !important;
}
.no-permission {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.translate {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #f0f2f5;
  .platte-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .mask {
    position: absolute;
    background: #409eff;
    opacity: 0.4;
    z-index: 999;
    transition: none !important;
  }
  .translate-top {
    height: 52px;
    background: #fff;
    position: relative;
    display: flex;
    .translate-top-center {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      .translate-top-center-content {
        width: 100%;
        height: 100%;
      }
    }
  }
  .translate-bottom {
    background: #f0f2f5;
    margin-top: 12px;
    height: calc(100% - 64px);
    display: flex;
    position: relative;
    justify-content: space-between;
    > div {
      height: 100%;
    }
    .translate-bottom-left {
      width: 10%;
      transition: all 0.3s;
      &:hover {
        .translate-bottom-left-show-icon {
          opacity: 1;
        }
      }
      > div {
        height: 100%;
        position: relative;
        background: #fff;
        .translate-bottom-left-show-icon {
          position: absolute;
          z-index: 700;
          top: calc(50% - 50px);
          right: -10px;
          display: flex;
          height: 100px;
          width: 10px;
          background: #fff;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
          font-size: 9px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s;
          opacity: 0;
          &:hover {
            color: var(--theme-color);
          }
        }
      }
    }
    .translate-bottom-center {
      flex: 1;
      width: 85%;
      padding: 0 24px 0 24px;
      user-select: none;
      > div {
        width: 100%;
        height: 100%;
        background: #fff;
      }
    }
    .translate-bottom-right {
      width: 5%;
      > div {
        width: 100%;
        height: 100%;
        position: relative;
        .option-list {
          position: absolute;
          padding: 8px;
          width: 100%;
          &:hover {
            .translate-bottom-right-show-icon {
              opacity: 1;
            }
          }
          right: 0;
          background-color: #fff;
          border-radius: 5px;
          height: 100%;
          .translate-bottom-right-show-icon {
            position: absolute;
            z-index: 700;
            top: calc(50% - 50px);
            left: -10px;
            display: flex;
            height: 100px;
            width: 10px;
            background: #fff;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            font-size: 9px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;
            opacity: 0;
            &:hover {
              color: var(--theme-color);
            }
          }
          .option {
            border-radius: 5px;
            margin-bottom: 8px;
            line-height: 32px;
            text-align: center;
            height: 32px;
            padding: 0 10px 0 10px;
            transition: all 0.3s;
            background: #f0f2f5;
            &:hover {
              color: var(--theme-color);
            }
            cursor: pointer;
          }
          .select-option {
            color: var(--theme-color);
          }
        }
      }
    }
  }
}
.ant-slider {
  width: 7%;
  margin-top: 12px;
  margin-left: 15px;
}
/deep/ .ant-slider-rail {
  background-color: #e1e1e1 !important;
}
/deep/ .ant-slider-track {
  background-color: var(--theme-color) !important;
}
/deep/ .ant-slider-handle:focus {
  border-color: var(--theme-color);
  outline: none;
  box-shadow: unset !important;
}
/deep/ .ant-slider-handle:hover {
  border-color: #6d6e72;
  outline: none;
  box-shadow: 0 0 0 0px var(--theme-color) !important;
}
/deep/ .ant-slider-handle {
  border: 2px solid rgb(223, 220, 221);
}
::-webkit-scrollbar-button {
  height: 0;
}
::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
@media screen and (max-width: 1820px) {
  .option-list {
    font-size: 12px;
  }
}
@media screen and (max-width: 1660px) {
  .option {
    font-size: 12px;
    padding: 0 5px 0 5px !important;
  }
}
@media screen and (max-width: 780px) {
  .translate-top-left-content {
    > div {
      .anticon {
        font-size: 16px !important;
        margin-right: 5px !important;
        display: none !important;
      }
      .i-icon {
        display: none !important;
      }
    }
  }
}
.all-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  user-select: none;
}
</style>
